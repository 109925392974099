import React from 'react';
import { SingleChoice } from './SingleChoice.js';
import { NumberInput } from './NumberInput';
import { TextInput } from './TextInput';
import { MultipleChoice } from './MultipleChoice';

export const Question = ({ question, currentAnswer, setAnswers, currentQuestionIndex, specifyOptions}) => {
  if (!question) return null;

  switch (question.type) {
    case 'single-choice':
      return (
        <SingleChoice
          question={question}
          currentAnswer={currentAnswer}
          setAnswers={setAnswers}
          currentQuestionIndex={currentQuestionIndex}
          specifyOptions={specifyOptions}
        />
      );
    case 'number':
      return (
        <NumberInput
          question={question}
          currentAnswer={currentAnswer}
          setAnswers={setAnswers}
          currentQuestionIndex={currentQuestionIndex}
        />
      );
    case 'text':
      return (
        <TextInput
          question={question}
          currentAnswer={currentAnswer}
          currentQuestionIndex={currentQuestionIndex}
          specifyOptions={specifyOptions}
        />
      );
    case 'multiple-choice':
      return (
        <MultipleChoice
          question={question}
          currentAnswer={currentAnswer}
          setAnswers={setAnswers}
          currentQuestionIndex={currentQuestionIndex}
          specifyOptions={specifyOptions}
        />
      );
    default:
      return null;
  }
};
