import React, { useEffect } from 'react';
import '../style/Sign-login.css';
import toast from 'react-hot-toast';
import '@fortawesome/fontawesome-free/css/all.css';

export function FacebookAuth({ onSuccess }) {
  useEffect(() => {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID, // Ensure this is set
        cookie: true,
        xfbml: true,
        version: 'v10.0'
      });

      window.FB.AppEvents.logPageView();
    };

    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }, []);
  
  const handleFacebookLogin = () => {
    window.FB.login((response) => {
      if (response.authResponse) {
        window.FB.api('/me', { fields: 'name,email' }, function(profileResponse) {
          onSuccess({
            ...response.authResponse,
            email: profileResponse.email,
            name: profileResponse.name,
          });
        });
      } else {
        toast.error("User cancelled login or did not fully authorize.")
      }
    }, { scope: 'email' });
  };

  return (
    <a className="link-a" onClick={handleFacebookLogin} aria-label="Continue with Facebook">
      <i style={{ color: '3b5999', marginRight: '5px' , marginLeft: '7px' }} className="fa-brands fa-facebook"></i>
    </a>
  );
}

export default FacebookAuth;
