import { useData } from "./api/store";
import toast from 'react-hot-toast';

export function Parsing_file_input(event, setIsFileUploaded , setErrState , errState)
{
	const { setUploadedFiles, uploadedFiles } = useData.getState();
    const newFile = Array.from(event.target.files);
		const isFileSizeValid = newFile.every((file) => file.size <= 10000000)
		const allowedExtensions = ['pdf', 'png', 'jpg', 'jpeg'];
		if (!isFileSizeValid)
		{
			toast.error("the size of this file is too large, the maximum is 10MB");
			setErrState(true);
			setIsFileUploaded(false);
			return false;
		}
		else if (newFile.length > 10)
		{
			toast.error("the amount files is more than 10, the maximum is 10");
			setErrState(true);
			setIsFileUploaded(false);
			return false;
		}
		for (const file of newFile) {
			const reader = new FileReader();
		
			reader.onloadend = (e) => {
			  const arr = new Uint8Array(e.target.result).subarray(0, 4);
			  const header = arr.reduce((header, byte) => header + byte.toString(16), '');
			  let fileType = '';
		
			  switch (header) {
				case '89504e47':
				  fileType = 'image/png';
				  break;
				case 'ffd8ffe0':
				case 'ffd8ffe1':
				case 'ffd8ffe2':
				case 'ffd8ffe3':
				case 'ffd8ffe8':
				  fileType = 'image/jpeg';
				  break;
				case '25504446':
				  fileType = 'application/pdf';
				  break;
				default:
				  setErrState(true);
				  setIsFileUploaded(false);
				  toast.error("Invalid file format");
				  return false;
			  }
		
			  if (!allowedExtensions.includes(fileType.split('/')[1])) {
				setErrState(true);
				setIsFileUploaded(false);
				toast.error("Invalid file format");
				return false;
			  }
			};
		
			reader.readAsArrayBuffer(file);
		  }
	if (!errState)	
	{
		setErrState(false);
		setIsFileUploaded(true);
		toast.success("Your Document is uploaded successfully");
		return true;
	}
}