import React, { useEffect, useState } from 'react';
import lodingimage from '../../res/LoadingResult.gif';
import '../style/LoadingResults.css';

const LoadingResults = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  
  const slides = [
    "This result is powered by AI. AI can make mistakes, so always consult with your doctor.",
    "Medical advice: Eat a balanced diet and exercise regularly.",
    "Warning: Do not self-medicate. Always follow your doctor's advice.",
    "Stay hydrated and get enough sleep for better health.",
    "Regular check-ups can help detect health issues early."
  ];

  useEffect(() => {
    // Update slide every 5 seconds
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(slideInterval);
  }, [slides.length]);

  return (
    <div className="loading-container">
      <img className='imgLoading' src={lodingimage} alt="Loading" />
      <div className="slideshow">
        <p>{slides[currentSlide]}</p>
      </div>
    </div>
  );
};

export default LoadingResults;
