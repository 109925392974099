import { useData } from '../api/store';
import axios from '../api/axios';
import toast from 'react-hot-toast';
// import { parseVendorFromEmail } from './getVendor';
import { setAuthTokenCookie } from './setAuthTokenCookie';
const GET_USER_TOKEN = process.env.REACT_APP_BASE_URL + "/api/register_user/";

export async function handleUserSignUp(navigate, otpString) {
    const { email, user, setErrMsg, setOtpShow, setSignUpActive, setLoggedIn , setUser, setEmail,signInForVisitor , setSignInForVisitor , consent , setConsent} = useData.getState();
    // let vendor;

    if (!email || !user || !consent) {
        setErrMsg('Please enter email and user name');
        return;
    }
    try {
        // vendor = parseVendorFromEmail(email);
        const userTokenResponse = await axios.post(GET_USER_TOKEN, {
            vendor: "email",
            email: email,
            access_token: otpString,
            name: user,
            consented: "True",
        }, {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        });

        if (userTokenResponse.status === 201 || userTokenResponse.status === 200) {
            setAuthTokenCookie(userTokenResponse.data.token);  
            setOtpShow(false);
            setSignUpActive(false);
            setEmail(userTokenResponse.data.email);
            setUser(userTokenResponse.data.name);
            setConsent(true);
            setLoggedIn(true);
            toast.success('User logged in successfully');
            if (signInForVisitor)
            {
                setSignInForVisitor(false);
                return ;
            } else {
                navigate(-1);
            }
        } else {
            toast.error('Failed to get user token');
            setOtpShow(false);
            setSignUpActive(true);
        }
    } catch (error) {
        setOtpShow(false);
        setConsent(false);
        setSignUpActive(true);
        toast.error("User creation failed");
    }
}
