// UploadButton.js
import React, { useState, useRef } from 'react';
import { FiUploadCloud } from 'react-icons/fi';
import { useData } from './api/store';
import { Parsing_file_input } from './Parsing_file_input';
import LongConsent from './LongConsent';
import translations from './translations';

const UploadButton = ({ setFiles }) => {
  const [ConsentStateChange, setConsentStateChange] = useState(false);
  const [errState, setErrState] = useState(false);
  const fileInputRef = useRef(null);
  const {
    consent,
    setConsent,
    setIsFileUploaded,
    setSignInForVisitor,
    navigate,
    activeLang,
  } = useData();
  const lang = translations[activeLang];
  
  const handleClick = () => {
    if (!consent) {
      setConsentStateChange(true);
      return;
    }
    if (fileInputRef.current) {
      const newInput = document.createElement('input');
      newInput.type = 'file';
      newInput.accept = '.jpg,.jpeg,.png,.pdf';
      newInput.multiple = true;
      newInput.style.display = 'none';
      newInput.onchange = handleChange;
      document.body.appendChild(newInput);
      newInput.click();
    } else {
      fileInputRef.current.click();
    }
  };

  const handleChange = async (event) => {
    if (await Parsing_file_input(event, setIsFileUploaded, setErrState, errState) === false) {
      return;
    } else {
      const formData = new FormData();
      Array.from(event.target.files).forEach((file) => {
        formData.append('uploaded_file', file);
      });
      setFiles(formData);
      setIsFileUploaded(true);
    }
  };

  const handleCheckboxChange = () => {
    setConsentStateChange(false);
    setConsent(true);
  };

  const handleReload = (event) => {
    event.preventDefault();
    setConsentStateChange(false);
  };

  return (
    <div className="uploud-btn-div">
      {ConsentStateChange && (
        <LongConsent handleCheckboxChange={handleCheckboxChange} handleReload={handleReload} />
      )}
      <input ref={fileInputRef} onChange={handleChange} style={{ display: 'none' }} multiple />
      <p className='warning-info'>{lang.warning}</p>
      <button className="btn-upload" onClick={handleClick}>
        <span>{lang.uploadButton}</span>
        <i className="file-icon"><FiUploadCloud /></i>
      </button>
    </div>
  );
};

export default UploadButton;
