import React, { useEffect, useState } from 'react';
import { useData } from '../api/store';
import CardResults from './CardResults';
import Summary from './Summary';
import QuestionsResults from './QuestionsResults';
import Recommendations from './Recommendations';
import FeedbackModal from '../FeedBackModal';
import ChatBox from './ChatBox';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import '../style/Results.css';
import translations from '../translations';

const ResultPage = () => {
  const [loadingResults, setLoadingResults] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const { setErrMsg, errMsg, results, setIsFileUploaded, setLoading, setResults , activeLang, setActiveLang} = useData();
  const lang = translations[activeLang];
  const navigate = useNavigate();

  let parsedGraph = null;
  if (results && results.graph && typeof results.graph === 'string') {
    try {
      const parsed = JSON.parse(results.graph);
      if (typeof parsed === 'object' && parsed !== null) {
        parsedGraph = parsed;
      }
    } catch (e) {
      toast.error('Failed to parse graph data');
      setErrMsg('Failed to parse graph data');
      parsedGraph = null;
    }
  }

  useEffect(() => {
    setLoading(false);
    setIsFileUploaded(false);
    if (results && results.summary && results.questions && results.tips) {
      setLoadingResults(false);
      if (results.language.includes('Arabic'))
          setActiveLang('ar');
    }
 
    else {
      toast.error('No results found');
      setLoadingResults(false);
      navigate("/History");
    }
  }, []);

  const openFeedbackModal = () => setIsFeedbackModalOpen(true);
  const closeFeedbackModal = () => setIsFeedbackModalOpen(false);

  return (
    <div className="result-page" id="result-page">
      {errMsg && <p className='errorMessage alert alert-danger ' role='role'>{errMsg}</p>}
      <div className=" results">
        <div className="first-page">
          <div className={`${activeLang === 'ar' ? 'onlyRtl' : ''}`}>
          <h1 className='result-title'> {lang.resultType}:</h1>
          <div className='results-btns'>
            {results && results.summary && (
              <>
              <a href='#chatbox' className='chatBoxBtn'>
              {lang.chatWithAI} <i className="fa fa-robot"></i>
                </a>
                <button onClick={openFeedbackModal} className="feedback-result-button">
                  {lang.feedback} <i className='fa fa-comments'></i>
                </button>
                <a className="buyMeCoffee-result-button" href='https://ko-fi.com/D1D4128L6U'>
                  {lang.supportTahlili} <i className="fa-solid fa-circle-dollar-to-slot"></i>
                </a>
              </>
            )}
          </div>
          </div>
          <div className="test-results">
          {parsedGraph ? (
            Object.keys(parsedGraph).length > 0 ? (
              Object.keys(parsedGraph).map(key =>
                Array.isArray(parsedGraph[key]) ? (
                  parsedGraph[key].map(item => (
                    item && (
                      <CardResults
                        key={item.id}
                        label={item.name || "N/A"}
                        value={item.value || "N/A"}
                        range={[item["normal-lower"] || 0, item["normal-upper"] || 0]}
                        status={item.status || "Unknown"}
                        units={item.units || "N/A"}
                        summaryOnHover={item.summaryOnHover || "No summary available"}
                      />
                    )
                  ))
                ) : (
                  <div key={key}>Invalid data format for key: {key}</div>
                )
              )
            ) : (
              <div></div>
            )
          ) : (
            <div>Loading or invalid data...</div>
          )}
        </div>
        </div>
        {results && results.summary && <Summary summary={results.summary} />}
      </div>
      <div className={`second-page ${activeLang === 'ar' ? 'onlyRtl' : ''}`}>
        {results && results.questions && <QuestionsResults questions={results.questions} />}
        {results && results.tips && <Recommendations tips={results.tips} />}
      </div>
      {results && <ChatBox results={results} />}
      <FeedbackModal isOpen={isFeedbackModalOpen} onRequestClose={closeFeedbackModal} />
    </div>
  );
};

export default ResultPage;
