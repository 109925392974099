import Confetti from "./Questionnaire/magicui/confetti";

export default function MsgSentSuccess() {
    return (
        <>
        <Confetti className="Confetti" />
        <div className="popup-content-contact-us">
            <p>🎉 Your message is sent successfully 🎉 
            <br></br>Thank you for sharing your thoughts with us,
            <br></br>
             we will be in touch soon </p>
        </div>
        </>
    );
}