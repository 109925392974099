import React from 'react';
import axios from '../api/axios';
import { useData } from '../api/store';
import toast from 'react-hot-toast';
import DOMPurify from 'dompurify';
import { validCountries } from './countries';

const SETTINGS_SUBMIT_URL = process.env.REACT_APP_BASE_URL + "/api/set_user_settings/";

const SaveButton = ({ language, birthday, country, subscribed, name }) => {
  const { setLoadingPage ,  activeLang} = useData();

  const validateName = (name) => {
    if (!name) {
      return '';
    }

    const sanitizedInput = DOMPurify.sanitize(name);
    if (sanitizedInput.length > 56) { // Longest country name is 56 characters long
      toast.error('Name cannot exceed 56 characters.');
      return false;
    }
    if (/[^a-zA-Z\s]/.test(sanitizedInput)) {
      toast.error('Name cannot contain special characters.');
      return false;
    }

    return sanitizedInput;
  };

  const validateBirthday = (dob) => {
    if (!dob) {
      return '';
    }
    if (!/^\d{4}-\d{2}-\d{2}$/.test(dob)) {
      toast.error('Date of Birth must be in the format YYYY-MM-DD.');
      return false;
    }
    return dob;
  };

  const validateLanguage = (lang) => {
    const validLangs = ['en', 'ar'];
    if (lang && !validLangs.includes(lang)) {
      toast.error('Invalid language selected.');
      return false;
    }
    return lang || '';
  };

  const validateCountry = (cnt) => {
    if (cnt && (!validCountries['en'].includes(cnt) && !validCountries['ar'].includes(cnt))) {
      toast.error('Invalid country selected.');
      return false;
    }
    return cnt || '';
  };

  const handleSubmit = async () => {
    setLoadingPage(true);
    console.log(language);
    console.log(country);
    const validatedName = validateName(name);
    const validatedBirthday = validateBirthday(birthday);
    const validatedLanguage = validateLanguage(language);
    const validatedCountry = validateCountry(country);

    if (validatedName === false || validatedBirthday === false || validatedLanguage === false || validatedCountry === false) {
      setLoadingPage(false);
      return;
    }

    try {
      const response = await axios.post(SETTINGS_SUBMIT_URL, {
        language: validatedLanguage,
        birthday: validatedBirthday,
        country: validatedCountry,
        subscribed: subscribed,
        name: validatedName,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      if (response.status === 200 || response.status === 201) {
        toast.success('Settings updated successfully!');
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          toast.error('Failed to update settings');
        }
      } else {
        toast.error('An unexpected error occurred. Please try again.');
      }
    } finally {
      setLoadingPage(false);
    }
  };

  return (
    <div className='save-btn-div'>
    <button className="save-btn" onClick={handleSubmit}>
      Save information
    </button>
    </div>
  );
};

export default SaveButton;
