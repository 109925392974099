import React, { useState, useEffect } from 'react';
import policyImage from '../../res/policyImage.svg';
import { useData } from '../api/store';
import translations from '../translations';
import '../style/PrivacyPolicy.css';

export function PrivacyPolicy() {
    const [activeTab, setActiveTab] = useState('privacy_policy');
    const { policyAndTerms , policyAndTermsAr, activeLang} = useData();
    const lang = translations[activeLang];

    useEffect(() => {
        const handleHashChange = () => {
            const hash = window.location.hash.substring(1);
            setActiveTab(hash || 'privacy_policy');
        };

        window.addEventListener('hashchange', handleHashChange);
        handleHashChange();

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);

    const renderWithLineBreaks = (text) => {
        if (!text) 
            return "No terms found, Please do not proceed further without reading and accepting terms and conditions";
        return text.split(/(?:\r\n|\r|\n)/g).map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };

    const cleanTitle = (title) => {
        if (title === 'privacy_policy') {
            return <h1>{lang.privacyPolicyTitle}</h1>;
        }
        if (title === 'terms_and_conditions') {
            return <h1>{lang.termsAndConditionsTitle}</h1>;
        }
        if (title === 'consent') {
            return <h1>{lang.consentTitle}</h1>;
        }
    };

    return (
        <div className="privacy-container">
            <div className="privacy-sidebar">
                <nav className={`privacy-nav ${activeLang === 'ar' ? 'onlyRtl' : ''}`}>
                    <a
                        href="#consent"  
                        className={activeTab === 'consent' ? 'tab activeTab' : 'tab'}
                    >
                        {lang.consentTitle}
                    </a>
                    <a
                        href="#terms_and_conditions"
                        className={activeTab === 'terms_and_conditions' ? 'tab activeTab' : 'tab'}
                    >
                        {lang.termsAndConditionsTitle}
                    </a>
                    <a
                        href="#privacy_policy"
                        className={activeTab === 'privacy_policy' ? 'tab activeTab' : 'tab'}
                    >
                        {lang.privacyPolicyTitle}
                    </a>
                </nav>
                <img src={policyImage} alt="Decorative" className="sidebar-image" />
            </div>
            <div className="privacy-content">
                <div className="privacy-header">
                    {cleanTitle(activeTab)}
                </div>
                <div className={`privacy-body ${activeLang === 'ar' ? 'onlyRtl' : ''}`}>
                    {activeLang === 'ar' ?
                    <p>{renderWithLineBreaks(policyAndTermsAr[activeTab])}</p>
                    :
                    <p>{renderWithLineBreaks(policyAndTerms[activeTab])}</p>
                    }
                </div>
            </div>
        </div>
    );
}
