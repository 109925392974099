// LandingPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useData } from './api/store';
import { Questionnaire } from './Questionnaire/Questionnaire';
import Footer from './Footer';
import image from '../res/home2-1.png';
import Welcome from './Welcome';
import HowItWorks from './HowItWorks';
import UploadButton from './UploadButton'; // Import the new component
import './style/LandingPage.css';
import '../App.css';
import translations from './translations';

function LandingPage() {
  const { isFileUploaded, setIsFileUploaded,activeLang } = useData();
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const lang = translations[activeLang];
  const isRTL = activeLang === 'ar'; 


  return (
    <>
      <div className={`snap-container ${isRTL ? 'rtl-layout' : ''}`}>
        {isFileUploaded ? (
          <Questionnaire files={files} navigate={navigate} setFiles={setFiles}/>
        ) : (
          <>
            <div className="snap-section section1">
              <div className="container">
                <div className="row landing-page-container" id='upload'>
                  <div className={`col-lg-6 ${isRTL ? '' : 'uploud-div'}`}>
                    <div className="upload-lab-text-container">
                      <h1 className='uploud-lab-text '>{lang.uploadTitle} <span className="simpelified" style={{ fontSize: '40px' }}>{lang.uploadTitle2}</span></h1>
                      <div className='subtitle-landingpage'>
                        <span className='simpelified'>{lang.uploadSubtitle1}</span><br />
                        <span className='simpelified'>{lang.uploadSubtitle2}</span>
                      </div>
                      <UploadButton setFiles={setFiles} />
                    </div>
                  </div>
                  <div className={`col-lg-6 ${isRTL ? 'landing-container-img' : ''}`}>
                    <img src={image} alt="Image" className='landing-img' />
                  </div>
                </div>
              </div>
            </div>
            <div className="snap-section section2">
              <Welcome />
            </div>
            <div className='snap-section'>
              <HowItWorks />
            </div>
            <div className='snap-section'>
              <Footer />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default LandingPage;
