// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(26, 30, 39); /* Background color */
  color: #fff; /* Text color */
  z-index: 999;
}

.imgLoading {
  width: 40%;
  height: auto; /* Maintain aspect ratio */
  max-height: 63%; /* Constrain height */
  margin-bottom: 20px; /* Space between image and progress bar */
}

.progress-bar-container {
  width: 60%;
  height: 10px;
  background-color: #555;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}

.progress-bar {
  height: 100%;
  background-color: #2eeefc;
  transition: width 0.1s ease;
}

.slideshow {
  text-align: center;
  max-width: 80%;
  font-size: 1.5rem;
  margin: 20px;
}

.slideshow p {
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/component/style/LoadingResults.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,iCAAiC,EAAE,qBAAqB;EACxD,WAAW,EAAE,eAAe;EAC5B,YAAY;AACd;;AAEA;EACE,UAAU;EACV,YAAY,EAAE,0BAA0B;EACxC,eAAe,EAAE,qBAAqB;EACtC,mBAAmB,EAAE,yCAAyC;AAChE;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,SAAS;AACX","sourcesContent":[".loading-container {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  background-color: rgb(26, 30, 39); /* Background color */\n  color: #fff; /* Text color */\n  z-index: 999;\n}\n\n.imgLoading {\n  width: 40%;\n  height: auto; /* Maintain aspect ratio */\n  max-height: 63%; /* Constrain height */\n  margin-bottom: 20px; /* Space between image and progress bar */\n}\n\n.progress-bar-container {\n  width: 60%;\n  height: 10px;\n  background-color: #555;\n  border-radius: 5px;\n  overflow: hidden;\n  margin-bottom: 20px;\n}\n\n.progress-bar {\n  height: 100%;\n  background-color: #2eeefc;\n  transition: width 0.1s ease;\n}\n\n.slideshow {\n  text-align: center;\n  max-width: 80%;\n  font-size: 1.5rem;\n  margin: 20px;\n}\n\n.slideshow p {\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
