const translations = {
  en: {
    home: "Home",
    whoAreWe: "Who are we?",
    contactUs: "Contact Us",
    privacyPolicy: "Privacy Policy",
    feedback: "Feedback",
    profile: "Profile",
    signIn: "Sign in",
    signUp: "Sign up",
    signInMobile: "you don't have an account ? Sign up",
    signUpMobile: "already have an account ? Sign in",
    supportTahlili: "Support Tahlili",
    cookiesConsent: "This website uses cookies to enhance the user experience.",
    learnMore: "Learn more",
    understand: "I understand",
    uploadTitle: "YOUR HEALTH, ",
    uploadTitle2: "SIMPLIFIED",
    uploadSubtitle1: "Upload your lab test results, and let Tahlili's AI transform them into clear, simple insights just for you.",
    uploadSubtitle2: "Start understanding your health with Tahlili now! ",
    warning: "AI can make mistakes. Check important info.",
    uploadButton: "Upload Your Lab Test",
    welcomeTitle: "Welcome To Tahlili",
    welcomeDescription: "At Tahlili, we believe everyone deserves to easily understand and manage their health. We're a team of passionate programmers aiming to make health information clear and simple. Using advanced AI, we offer easy tools and helpful insights to guide you through your healthcare journey. Our goal is to make staying healthy easier and accessible for everyone. With Tahlili, you'll have the support and knowledge you need for a healthier life.",
    startButton: "START",
    howItWorksTitle: "How It Works?",
    uploadLabTest: "Upload Your Lab Test Result",
    uploadLabTestDescription: "Choose the file/image of your test result that you want to upload from your device.",
    answerQuestionnaire: "Answer the Questionnaire",
    answerQuestionnaireDescription: "Enter a detailed description of your condition. Tell the AI what you suffer from.",
    getResult: "Get Your Result",
    getResultDescription: "Get your result from AI. You can print the page or save it as a PDF file.",
    title: 'Get in touch',
    namePlaceholder: 'Full name',
    emailPlaceholder: 'yourmail@email.com',
    messagePlaceholder: 'Enter your message here',
    buttonText: 'Send my message',
    errMsgEmptyField: 'Fill the empty field.',
    errMsgInvalidEmail: 'Invalid Email Address.',
    errMsgLongMessage: 'Message is too long',
    errMsgSendFail: 'Something went wrong, Please try again later',
    aboutTitle: "Who are we?",
    aboutUsTitle: "About Us",
    aboutUsDescription: "We are Tahlili, and we are committed to redefining wellness. Our mission is to empower you to take control of your health and happiness with personalized, accessible solutions. Our team of passionate experts in technology, health, and well-being harnesses the power of advanced AI to deliver innovative tools and resources. At Tahlili, we simplify your wellness journey, helping you unlock your full potential and thrive.",
    socialMedia: "Social Media",
    privacyPolicyTitle: "Privacy Policy",
    termsAndConditionsTitle: "Terms and Condition",
    noTermsFound:"No terms found, Please do not proceed further without accepting reading terms and conditions",
    iAgreeOn:" I Agree on",
    consentTitle:"User Consent",
    deleteAccount:"Delete Account",
    deleteAccountBtn:"Delete Account",
    cancel:"Cancel",
    Agree:"I Agree",
    settingTitle: "Settings",
    logoutBtn: "Logout",
    profileSett:"Profile",
    GeneralSett:"General",
    LanguageRegionSett: "Language & Region",
    FullName:"Full name",
    DateOfBirth:"Date of Birth",
    PreferencesTitle:"Preferences",
    PreferencesOpt_1:"Receive an email monthly updates",
    SelectLanguage : "Select Language",
    SelectRegion : "Select Region",
    Questionnaire: "Questionnaire",
    questionsNum:"Question",
    questionsNumOf:"of",
    questionsInfoH2:"The questionnaire has been completed successfully.",
    questionsInfoP:"We want to remind you that the results are generated by AI, which relies on the accuracy of the data you provided. Please ensure all your answers are correct, as this information is essential for the AI to deliver the most accurate and beneficial results. Additionally, we emphasize that AI can make mistakes, and it is always important to consult with your healthcare provider for professional advice. Rest assured that all your information is kept safe and private, and we are committed to maintaining the highest standards of data security and confidentiality.",
    questionsInfoSpan:"Thank you for taking proactive steps toward managing your health. Your commitment to your well-being is truly inspiring, and we are here to support you every step of the way.",
    congratsTitle: "Congratulations! You've completed the",
    congratsText: "Keep it up! Answer accurately for precise results.",
    categort:"category",
    Q_nextbtn:"Next",
    Q_backbtn:"Back",
    Q_submitbtn:"Submit",
    resultType:"RESULTS",
    savePdf:"Save your result as PDF ",
    summary:"Summary",
    questionsToAskYourDoctor:"Questions to Ask your Doctor",
    tips:"Recommendations",
    loginSignIntitle:"Sign in",
    loginSignInBtn:"Sign in",
    OrSignInwith:"Or Sign in with",
    helloFreind:"Hello, Friend!",
    helloFreindParaghraph:"Enter your personal details to use all of site features",
    welcomeBack:"Welcome Back!",
    welcomeBackParagraph:"Register with your personal details to use all of site features",
    CreateAccount:"Create Account",
    CreateAccountName:"Name",
    CreateAccountEmail:"Email",
    CreateAccountEmailInstructions1:"4 to 24 characters.",
    CreateAccountEmailInstructions2:" Must begin with a letter.",
    CreateAccountEmailInstructions3:" Letters, numbers, underscores, hyphens allowed.",
    OrSignUpWith:"Or Sign up with",
    SignUpBtn:"Sign Up",
    IAgreeSignUp:"I Agree to the terms and conditions",

  },
  ar: {
    home: "الرئيسية",
    whoAreWe: "من نحن؟",
    contactUs: "تواصل معنا",
    privacyPolicy: "سياسة الخصوصية",
    feedback: "رأيك يهمنا",
    profile: "الملف الشخصي",
    signIn: "تسجيل الدخول",
    signUp: "انشاء حساب",
    signInMobile: "ليس لديك حساب ؟ اشترك",
    signUpMobile: "لديك حساب بالفعل؟ سجل دخول",
    supportTahlili: "ادعم تحليلي",
    cookiesConsent: "يستخدم هذا الموقع ملفات تعريف الارتباط لتحسين تجربة المستخدم.",
    learnMore: "أعرف أكثر",
    understand: "أفهم",
    uploadTitle: "صحتك،",
    uploadTitle2: " مبسطة",
    uploadSubtitle1: ".حمّل نتائج تحاليلك الآن، ودع الذكاء الاصطناعي لتحليلي يحولها إلى نصائح واضحة ومبسطة لك",
    uploadSubtitle2: "!ابدأ بفهم صحتك مع تحليلي الآن ",
    warning: "يمكن للذكاء الاصطناعي أن يخطئ. تحقق من المعلومات المهمة.",
    uploadButton: "حمل اختبارك المعملي",
    welcomeTitle: "مرحبا بك في تحليلي",
    welcomeDescription: "!تحليلي هو مساعدك الذكي لفهم حالتك الصحية بشكل أفضل. يحول نتائج اختباراتك الصحية إلى معلومات مبسطة وسهلة القراءة. بالإضافة إلى ذلك، يوفر نصائح وإرشادات مخصصة تناسب حالتك الصحية، وكل ذلك مدعوم بتقنيات الذكاء الاصطناعي. مع تحليلي، يمكنك اتباع خطوات واضحة لتحسين صحتك والتمتع بحياة أفضل. ابدأ رحلتك نحو صحة أفضل مع تحليلي اليوم",
    startButton: "ابدأ",
    howItWorksTitle: "كيف يعمل؟",
    uploadLabTest: "قم بتحميل نتيجة اختبارك المعملي",
    uploadLabTestDescription: "اختر الملف/الصورة الخاصة بنتيجة اختبارك التي تريد تحميلها من جهازك.",
    answerQuestionnaire: "أجب على الاستبيان",
    answerQuestionnaireDescription: "أدخل وصفًا تفصيليًا لحالتك. أخبر الذكاء الاصطناعي بما كنت تعاني منه.",
    getResult: "احصل على نتيجتك",
    getResultDescription: "احصل على نتيجتك من الذكاء الاصطناعي. يمكنك طباعة الصفحة أو حفظها كملف PDF.",
    title: 'تواصل معنا',
    namePlaceholder: 'الاسم الكامل',
    emailPlaceholder: 'ادخل ايميلك الالكتروني',
    messagePlaceholder: 'أدخل رسالتك هنا',
    buttonText: 'إرسال الرسالة',
    errMsgEmptyField: 'يرجى تعبئة الحقول الفارغة.',
    errMsgInvalidEmail: 'عنوان البريد الإلكتروني غير صالح.',
    errMsgLongMessage: 'الرسالة طويلة جدًا',
    errMsgSendFail: 'حدث خطأ، يرجى المحاولة مرة أخرى لاحقًا',
    aboutTitle: "من نحن؟",
    aboutUsTitle: "من نحن",
    aboutUsDescription: "نحن تحليلي، ونحن ملتزمون بإعادة تعريف الرفاهية. مهمتنا هي تمكينك من السيطرة على صحتك وسعادتك من خلال حلول مخصصة وقابلة للوصول. فريقنا من الخبراء المتحمسين في التكنولوجيا والصحة والرفاهية يستخدم قوة الذكاء الاصطناعي المتقدم لتقديم أدوات وموارد مبتكرة. في تحليلي، نبسط رحلتك نحو الرفاهية، مما يساعدك على تحقيق كامل إمكاناتك والازدهار.",
    socialMedia: "وسائل التواصل الاجتماعي",
    privacyPolicyTitle: "سياسة الخصوصية",
    termsAndConditionsTitle: "الشروط و الأحكام",
    noTermsFound:"لم يتم العثور على شروط، يرجى عدم المتابعة دون قبول شروط وأحكام القراءة",
    iAgreeOn:"اوافق على",
    consentTitle:"موافقة المستخدم",
    deleteAccount:"حذف الحساب",
    deleteAccountBtn:"حذف",
    cancel:"إلغاء",
    Agree:"أوافق",
    settingTitle: "إعدادات",
    logoutBtn: "تسجيل الخروج",
    profileSett:" البيانات الشخصية",
    GeneralSett:"الإعدادات العامة",
    LanguageRegionSett: "اللغة والمنطقة",
    FullName:"الاسم الكامل",
    DateOfBirth:"تاريخ الميلاد",
    PreferencesTitle:"التفضيلات",
    PreferencesOpt_1:"احصل على تحديثات شهرية عبر البريد الإلكتروني",
    Questionnaire: "إستبيان",
    questionsNum:"سؤال رقم",
    questionsNumOf:"من",
    questionsInfoH2: "تم إكمال الاستبيان بنجاح.",
    questionsInfoP: "نود أن نذكركم بأن النتائج تم توليدها بواسطة الذكاء الاصطناعي، والذي يعتمد على دقة البيانات التي قدمتها. يرجى التأكد من أن جميع إجاباتك صحيحة، لأن هذه المعلومات ضرورية لكي يقدم الذكاء الاصطناعي نتائج دقيقة ومفيدة. كما نؤكد أن الذكاء الاصطناعي قد يرتكب أخطاء، ومن المهم دائمًا استشارة مقدم الرعاية الصحية للحصول على نصيحة مهنية. نؤكد لكم أن جميع معلوماتكم محفوظة بشكل آمن وسري، ونحن ملتزمون بأعلى معايير أمان البيانات والخصوصية.",
    questionsInfoSpan: "شكرًا لاتخاذك خطوات استباقية نحو إدارة صحتك و التزامك  أمر ملهم حقًا، ونحن هنا لدعمك في كل خطوة على الطريق.",
    congratsTitle: " مبروك! لقد أكملت فئة ",
    congratsText: "استمر في ذلك! أجب بدقة للحصول على نتائج دقيقة.",
    category: "",
    Q_nextbtn:"التالي",
    Q_backbtn:"رجوع",
    Q_submitbtn:"تم",
    resultType:"النتائج",
    savePdf:" احفظ نتيجنك كملف PDF ",
    summary:"ملخص",
    questionsToAskYourDoctor:"اسئلة يجب ان تسألها لطبيبك",
    tips:"توصيات",
    loginSignIntitle:"تسجيل الدخول",
    loginSignInBtn:"دخول",
    OrSignInwith:"او سجل دخولك بإستخدام ",
    helloFreind:"مرحباً",
    helloFreindParaghraph:"أدخل بياناتك الشخصية لاستخدام كافة ميزات الموقع",
    welcomeBack:"مرحبًا بعودتك!",
    welcomeBackParagraph:"قم بالتسجيل ببياناتك الشخصية لاستخدام كافة مميزات الموقع",
    CreateAccount:"إنشاء حساب",
    CreateAccountName:"إسم",
    CreateAccountEmail:"بريد إلكتروني",
    CreateAccountEmailInstructions1:"من 4 إلى 24 حرفًا.",
    CreateAccountEmailInstructions2:" يجب أن تبدأ بحرف.",
    CreateAccountEmailInstructions3:" يُسمح بالأحرف والأرقام والشرطات السفلية والشرطات.",
    OrSignUpWith:"أو قم بالتسجيل باستخدام",
    SignUpBtn:"تسجيل",
    IAgreeSignUp:"أوافق على الشروط والأحكام",
  }, 
};

export default translations;
