import React from 'react';
import { BsDribbble, BsFacebook, BsGithub, BsInstagram, BsTwitter } from "react-icons/bs";
import './style/Footer.css'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-grid">
          <div className="footer-section">
            <h3 className="footer-title">Company</h3>
            <ul className="footer-links">
              <li><a href="#">About</a></li>
              <li><a href="#">Careers</a></li>
              <li><a href="#">Brand Center</a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3 className="footer-title">Help Center</h3>
            <ul className="footer-links">
              {/* <li><a href="#">Discord Server</a></li> */}
              <li><a href="#">Intagram</a></li>
              <li><a href="#">LinkedIn</a></li>
              <li><a href="#">Facebook</a></li>
              <li><a href="#">Contact Us</a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3 className="footer-title">Legal</h3>
            <ul className="footer-links">
              <li><a href="#">Privacy Policy</a></li>
              {/* <li><a href="#">Licensing</a></li> */}
              <li><a href="#">Terms &amp; Conditions</a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3 className="footer-title">Download</h3>
            <ul className="footer-links">
              <li><a href="#">iOS</a></li>
              <li><a href="#">Android</a></li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2022 Tahlili</p>
          <div className="footer-social-icons">
            <a href="#"><BsFacebook /></a>
            <a href="#"><BsInstagram /></a>
            <a href="#"><BsTwitter /></a>
            {/* <a href="#"><BsGithub /></a> */}
            {/* <a href="#"><BsDribbble /></a> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
