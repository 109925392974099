import React, { useEffect } from 'react';
import FormLoading from '../Questionnaire/FormLoading';
import quesImage from '../../res/doctor-QA1.png';
import lineSegment from '../../res/line-segment.png';
import { useData } from '../api/store';
import translations from '../translations';

const QuestionsResults = ({ questions }) => {
  const { activeLang } = useData();
  const lang = translations[activeLang];
  return (
    <div className="container questions">
        {typeof questions === 'string' ?
      <div className='row'>
        <div className='col-lg-12'>
          <h1>{lang.questionsToAskYourDoctor}</h1>
        </div>
        <div className='col-lg-3'>
          <img src={quesImage} className='questions-image'/>
          <img src={lineSegment} className='lineSegment-icon-questions'/>
        </div>
        <div className='col-lg-9'>
           { questions.split('\n'||'\r'||'\r\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment >
            ))}
        </div>
      </div>
        : <FormLoading />
        }
    </div>
  );
}

export default QuestionsResults;
