import React, {useState} from 'react';
import '@fortawesome/fontawesome-free/css/all.css';


const getStatusColor = (status) => {
  switch (status) {
    case 'high':
      return '#DD3B41';
    case 'low':
      return 'orange';
    case 'normal':
      return '#24826F';
    default:
      return 'gray';
  }
};

const getIconClass = (status) => {
    switch (status) {
      case 'high':
        return 'fa fa-arrow-circle-up status-icon';
      case 'low':
        return 'fa fa-arrow-circle-down status-icon';
      case 'normal':
        return 'fa-solid fa-heart-pulse status-icon';
      default:
        return 'status-icon';
    }
  };

const CardResults = ({ label, value, range, status, units , summaryOnHover}) => {
  const statusColor = getStatusColor(status);
  const icon = getIconClass(status);
  const [showSummary, setShowSummary] = useState(false);

  const toggleSummary = () => {
    setShowSummary(!showSummary);
  };

  return (
    <div className="result-card-contianer" onMouseEnter={toggleSummary} onMouseLeave={toggleSummary}>
    <div className="result-card" style={{ color: statusColor }}>
      <p className="label" style={{ color: statusColor }} >{label}</p>
      <div className='value-div'>
      <p className="value" style={{ color: statusColor }}>{value} <span className='value-unit' style={{ color: statusColor }}> {units}</span></p>
      <div className="status-container">
          <i className={icon} style={{ color: statusColor }} />
          <span className="status-text" style={{ color: statusColor }}>{status}</span>
        </div>
      </div>
      <div className='range-div'>
      <p className="range">Normal range ({range[0]} - {range[1]} {units})</p>
      </div>
    </div>
    {showSummary && (
        <div className="summary-hover">
          <p>{summaryOnHover}</p>
        </div>
      )}
    </div>
  );
};

export default CardResults;

