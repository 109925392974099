// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.character-container {
    position: relative;
    width: 300px;
    margin: 0 auto;
  }
  
  .character-svg {
    width: 100%;
    height: auto;
  }
  
  .tooltip {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 8px;
    border-radius: 4px;
    pointer-events: none;
    z-index: 10;
  }
  `, "",{"version":3,"sources":["webpack://./src/component/style/CharacterInteractive.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,SAAS;IACT,2BAA2B;IAC3B,oCAAoC;IACpC,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,oBAAoB;IACpB,WAAW;EACb","sourcesContent":[".character-container {\n    position: relative;\n    width: 300px;\n    margin: 0 auto;\n  }\n  \n  .character-svg {\n    width: 100%;\n    height: auto;\n  }\n  \n  .tooltip {\n    position: absolute;\n    top: 10px;\n    left: 50%;\n    transform: translateX(-50%);\n    background-color: rgba(0, 0, 0, 0.7);\n    color: white;\n    padding: 8px;\n    border-radius: 4px;\n    pointer-events: none;\n    z-index: 10;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
