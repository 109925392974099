import axios from 'axios';
import { useData } from '../api/store';
import toast from 'react-hot-toast';
import { setAuthTokenCookie } from './setAuthTokenCookie';
const SEND_USER_OTP = process.env.REACT_APP_BASE_URL + "/api/send_email_otp/";

export async function sendOtp(navigate) {
    const { email, validEmail, setErrMsg, setSuccessMsg, setOtpShow, setOtpToken } = useData.getState();
    
    if (validEmail && typeof email === 'string' && email.length > 0) {
        try {
            const otpResponse = await axios.post(SEND_USER_OTP, { email });
            const { status, data } = otpResponse;

            if (status === 200 || status === 201 || status === 202) {
                const { token } = data;
                if (typeof token === 'string' && token.length > 0) {
                    toast.success("Please check your email for OTP.");
                    setAuthTokenCookie(token);
                    setOtpToken(token);
                    setOtpShow(true);
                } else {
                    throw new Error("Invalid token received");
                }
            } else if (status === 400) {
                setErrMsg("Invalid email format or bad request. Please try again.");
                toast.error("Invalid email format or bad request. Please try again.");
                navigate('/SignLogin');
            } else if (status === 409) {
                setErrMsg("User already exist. Please sign in.");
                toast.error("User already exist. Please sign in.");
                navigate('/SignLogin');
            } else {
                setErrMsg(`Unexpected status code: ${status}. Please try again.`);
                toast.error(`Unexpected status code: ${status}. Please try again.`);
                throw new Error("Failed to get user token");
            }
        } catch (error) {
            setErrMsg("User creation failed");
            toast.error("User creation failed");
            navigate('/SignLogin');
        }
    } else {
        setErrMsg("User creation failed");
        toast.error("User creation failed");
        navigate('/SignLogin');
    }
}
