import React, { useState } from 'react';
import { useData } from '../api/store';
import SaveButton from './SaveButton';
import translations from '../translations';

const Profile = () => {
  const {user, birthday , activeLang} = useData();
  const [name, setName] = useState(user);
  const [dob, setDob] = useState(birthday);
  const lang = translations[activeLang];

  return (
    <div className="settings-content">
      <h2>{lang.profileSett}</h2>
      <div className="form-group form-group-profile">
        <label>{lang.FullName}</label>
        <input 
          type="text" 
          className="form-control" 
          placeholder={lang.FullName} 
          value={name} 
          onChange={(e) => setName(e.target.value)} 
        />
      </div>
      <div className="form-group">
        <label>{lang.DateOfBirth}</label>
        <input
          type='date' 
          className='' 
          value={dob} 
          onChange={(e) => setDob(e.target.value)} 
        />
      </div>
      <SaveButton
        name={name}
        birthday={dob} 
        language={''}
        country={''}
        subscribed={''}
      />
    </div>
  );
};

export default Profile;
