import { useData } from '../api/store';
import toast from 'react-hot-toast';
import axios from 'axios';
import { setAuthTokenCookie } from './setAuthTokenCookie';

const GET_USER_TOKEN = process.env.REACT_APP_BASE_URL + "/api/register_user/";

export async function handleFacebookSignUp(response, navigate) {
  const { setLoggedIn , setConsent , setLoggedInUser, signInForVisitor , setSignInForVisitor} = useData.getState();
  const { email, accessToken, name } = response;

  try {
    const response = await axios.post(
      GET_USER_TOKEN,
      { 
        vendor: "facebook",
        email: email,
        access_token: accessToken,
        name: name,
        consented: "True",
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    );

    if (response.status === 200 || response.status === 201) {
      setAuthTokenCookie(response.data.token);
      toast.success('you logged in successfully')
      setLoggedInUser({
        username : response.data.name,
        email : response.data.email,
      })
      if (signInForVisitor)
        {
          setSignInForVisitor(false);
          return ;
        } else {
            navigate(-1);
          }
    }
  } catch (error) {
    setLoggedIn(false);
    setConsent(false);
    toast.error('Network error. Please try again.');
  }
}
