import React from 'react';
import labTestImage from '../res/819_generated.png';
import { useData } from './api/store';
import translations from './translations';
import './style/welcome.css';

const WelcomeSection = () => {


  const { activeLang } = useData();
  const lang = translations[activeLang];

  return (
    <section className="welcome-section-container welcome-animate-fade-in">
      <div className="welcome-container">
        <div className="welcome-grid-container">
          <img
            src={labTestImage} 
            width="600"
            height="400"
            alt="Insights"
            className="welcome-image welcome-animate-fade-in"
          />
          <div className="welcome-text-container welcome-animate-fade-up">
            <h2 className="welcome-heading">
              {lang.welcomeTitle}
            </h2>
            <p className="welcome-paragraph">
              {lang.welcomeDescription}
            </p>
            <div className="welcome-button-group welcome-animate-fade-up">
              <button className="welcome-secondary-button">
                {lang.startButton}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WelcomeSection;
