import { useData } from '../api/store';
import { setAuthTokenCookie } from './setAuthTokenCookie';
import axios from '../api/axios';
import toast from 'react-hot-toast';
const GET_USER_TOKEN =  process.env.REACT_APP_BASE_URL + "/api/login_user/";

export async function handleUserLogin(navigate, otpString) {
    const { email, setErrMsg, setLoggedIn, setOtpShow, setSignUpActive, setLoggedInUser, signInForVisitor , setSignInForVisitor} = useData.getState();
    if (!email) {
        setErrMsg('Please enter email and user name');
        setSignUpActive(false);
        setOtpShow(false);
        return;
    }
    try {
        const userTokenResponse = await axios.post(GET_USER_TOKEN, {
            vendor: "email",
            email: email,
            access_token: otpString,
        }, {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        });
        if (userTokenResponse.status === 200 || userTokenResponse.status === 201) {
            setAuthTokenCookie(userTokenResponse.data.token);  
            setOtpShow(false);
            setLoggedInUser({
                username : userTokenResponse.data.name,
                email : userTokenResponse.data.email,
            })
            toast.success('User logged in successfully');
            if (signInForVisitor)
            {
                setSignInForVisitor(false);
                return ;
            } else {
                navigate(-1);
            }
        }
    } catch (error) {
        if (userTokenResponse.status === 409) {
            toast.error('User not registered');
            setSignUpActive(true);
        } else if (error.response.status === 400) {
            toast.error('(Bad Request).. please try again');
        } else
            toast.error('Network Error, Try again later.')
        setOtpShow(false);
    }
}
