import { create } from 'zustand';

export const useData = create((set) => ({
    email: "",
    user: "",
    birthday: "DD/MM/YYYY",
    language: "en",
    country: "United Arab Emirates",
    subscribed: false,
    vendor: "",
    validName: false,
    validEmail: false,
    showConsent: false,
    successMsg: "",
    errMsg: "",
    success: false,
    error: false,
    consent: false,
    privacyTerms: {},
    otpShow: false,
    otpToken: '',
    isLoggedIn: false,
    Msg: "",
    isSignUpActive: false,
    loading: false,
    uploadedFiles: [],
    isFileUploaded: false,
    results: {
        graph: {},
        summary: '',
        questions: [],
        tips: []
    },
    loadingPage: false,
    signInForVisitor: false,
    policyAndTerms: {
        privacy_policy: '',
        terms_and_conditions: '',
        consent: '',
    },
    policyAndTermsAr: {
        privacy_policy: '',
        terms_and_conditions: '',
        consent: '',
    },
    LongConsent: '',
    LongConsent_ar: '',
    ShortConsent: '',
    ShortConsent_ar: '',
    deleteConsent: '',
    deleteConsent_ar: '',
    isGoogleLoggeeIn: false,
    fetchUserInfo: false,
    activeLang: "en",
    uploadAgain: false,
    setUploadAgain: (uploadAgain) => set({uploadAgain}),
    setActiveLang: (activeLang) => set({ activeLang }),  // Setter for activeLang
    setFetchUserInfo: (fetchUserInfo) => set({ fetchUserInfo }),
    setisGoogleLoggeeIn: (isGoogleLoggeeIn) => set({ isGoogleLoggeeIn }),
    setShortConsent: (ShortConsent) => set({ ShortConsent }),
    setShortConsentAr: (ShortConsent_ar) => set({ ShortConsent_ar }),
    setLongConsent: (LongConsent) => set({ LongConsent }),
    setLongConsentAr: (LongConsent_ar) => set({ LongConsent_ar }),
    setDeleteConsent: (deleteConsent) => set ({ deleteConsent }),
    setDeleteConsentAr: (deleteConsent_ar) => set ({ deleteConsent_ar }),
    setPolicyAndTerms: (policyAndTerms) => set({ policyAndTerms }),
    setPolicyAndTermsAr: (policyAndTermsAr) => set({ policyAndTermsAr }),
    setSignInForVisitor: (signInForVisitor) => set({ signInForVisitor }),
    setLoadingPage: (loadingPage) => set({ loadingPage }),
    setPrivacyTerms: (privacyTerms) => set({ privacyTerms }),
    setResults: (results) => set({ results }),
    setIsFileUploaded: (isFileUploaded) => set({ isFileUploaded }),
    setLoading: (loading) => set({ loading }),
    setUploadedFiles: (uploadedFiles) => set({ uploadedFiles }),
    setSignUpActive: (isSignUpActive) => set({ isSignUpActive }),
    setMsg: (Msg) => set({ Msg }),
    setError: (error) => set({ error }),
    setLoggedIn: (isLoggedIn) => set({ isLoggedIn }),
    setUser: (user) => set({ user }),
    setEmail: (email) => set({ email }),
    setBirthday: (birthday) => set({ birthday }),
    setLanguage: (language) => set({ language }),
    setCountry: (country) => set({ country }),
    setSubscribed: (subscribed) => set({ subscribed }),
    setVendor: (vendor) => set({ vendor }),
    setValidName: (validName) => set({ validName }),
    setValidEmail: (validEmail) => set({ validEmail }),
    setShowConsent: (showConsent) => set({ showConsent }),
    setSuccessMsg: (successMsg) => set({ successMsg }),
    setErrMsg: (errMsg) => set({ errMsg }),
    setSuccess: (success) => set({ success }),
    setConsent: (consent) => set({ consent }),
    setOtpShow: (otpShow) => set({ otpShow }),
    setOtpToken: (otpToken) => set({ otpToken }),
    setLoggedInUser: ({ username, email }) => set({
        user: username,
        email,
        consent: true,
        isLoggedIn: true,
    }),
}));
