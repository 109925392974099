import React, { useState, useEffect } from "react";
import translations from './translations'
import LoadingSpinner from './Questionnaire/FormLoading';

import { useData } from "./api/store";

const LongConsent = ({ handleCheckboxChange, handleReload }) => {
  const { LongConsent , LongConsent_ar , activeLang} = useData();
  const [checkedItems, setCheckedItems] = useState({
    privacy_policy: false,
    terms_and_conditions: false,
    consent: false
  });
  const lang = translations[activeLang];

  const handleCheckboxChangeInternal = (event, key) => {
    const { checked } = event.target;
    setCheckedItems(prev => ({
      ...prev,
      [key]: checked
    }));
  };

  useEffect(() => {
    const allChecked = Object.values(checkedItems).every(item => item);
    if (allChecked) {
      handleCheckboxChange();
    }
  }, [checkedItems]);

  const renderWithLineBreaks = (text) => {
    if (!text) 
      return lang.noTermsFound;
    return text.split(/(?:\r\n|\r|\n)/g).map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className={`consent-popup-container ${activeLang === 'ar' ? 'onlyRtl' : ''}`}>
      <div className="consent-popup">
        <div className="scrollable-content">
        {
            activeLang === 'ar' ?
            renderWithLineBreaks(LongConsent_ar)
            :
            renderWithLineBreaks(LongConsent)
          }
        </div>
        <div className="fixed-footer">
          <div className="consent-links-checkbox-div">
            <div className="consent-links-checkbox">
              <input type="checkbox" className='custom-control custom-checkbox' onChange={(e) => handleCheckboxChangeInternal(e, 'privacy_policy')} />
              {lang.iAgreeOn} <a href="/PrivacyPolicy#privacy_policy" target="_blank" rel="noreferrer"> {lang.privacyPolicyTitle}</a>
            </div>
            <div className="consent-links-checkbox">
              <input type="checkbox" className='custom-control custom-checkbox' onChange={(e) => handleCheckboxChangeInternal(e, 'terms_and_conditions')} />
              {lang.iAgreeOn} <a href="/PrivacyPolicy#terms_and_conditions" target="_blank" rel="noreferrer"> {lang.termsAndConditionsTitle}</a>
            </div>
            <div className="consent-links-checkbox">
              <input type="checkbox" className='custom-control custom-checkbox' onChange={(e) => handleCheckboxChangeInternal(e, 'consent')} />
              {lang.iAgreeOn} <a href="/PrivacyPolicy#consent" target="_blank" rel="noreferrer"> {lang.consentTitle}</a>
            </div>
          </div>
          <button className='btn btn-danger' onClick={handleReload}>{lang.cancel}</button>
        </div>
      </div>
    </div>
  );
}

export default LongConsent;
