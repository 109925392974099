import React, { useState , useEffect} from 'react';
import { sendOtp } from './sendOtp';
import { useNavigate } from 'react-router-dom';
import { useData } from '../api/store';
import '@fortawesome/fontawesome-free/css/all.css';
import '../style/Consent.css';
import LoadingPage from '../LoadingPage';
import translations from '../translations';

export function Consent(){
    const { ShortConsent , ShortConsent_ar, activeLang} = useData();
    const [loading, setLoading] = useState(false);
    const lang = translations[activeLang];
    const {
        consent,
        setConsent,
        setShowConsent,
    } = useData();
    const [checkedItems, setCheckedItems] = useState({
        privacy_policy: false,
        terms_and_conditions: false,
        consent: false
    });
    const navigate = useNavigate();

    const handleCheckboxChange  = async (e) => {
        setConsent(true);
        setLoading(true);
        await sendOtp(navigate);
        setShowConsent(false);
        setLoading(false);
    }

    const handleCancel = async (e) => {
        e.preventDefault();
        setShowConsent(false);
    }
    const renderWithLineBreaks = (text) => {
        if (text) {
        return text.split(/(?:\r\n|\r|\n)/g).map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
        } else {
            return lang.noTermsFound;
        }
    };

    const handleCheckboxChangeInternal = (event, key) => {
        const { checked } = event.target;
        setCheckedItems(prev => ({
            ...prev,
            [key]: checked
        }));
    };
    useEffect(() => {
        const allChecked = Object.values(checkedItems).every(item => item);
        if (allChecked) {
            handleCheckboxChange();
        }
    }, [checkedItems]);

    return (
        <>
        {loading && <LoadingPage />}
        {!loading && <div className="container-consent">
            <div className={`centered-consent ${activeLang === 'ar' ? 'onlyRtl' : ''}`}>
                <form className='consent-form'>
                <i className="fa fa-times icon-close-otp" aria-hidden="true" onClick={handleCancel}></i>
                <div className='scrollable-content-sign-up'>
                    <p>
                    {
                        activeLang === 'ar' ?
                        renderWithLineBreaks(ShortConsent_ar)
                        :
                        renderWithLineBreaks(ShortConsent)
                    }
                    </p>
                </div>
                <div className='fixed-footer'>
                    <div className="consent-links-checkbox-div-signup">
                        <div className="consent-links-checkbox-signup">
                            <input type="checkbox" className='custom-control custom-checkbox' onChange={(e) => handleCheckboxChangeInternal(e, 'privacy_policy')} />
                            {lang.iAgreeOn} <a href="/PrivacyPolicy#privacy_policy" target="_blank" rel="noreferrer"> {lang.privacyPolicyTitle}</a>
                        </div>
                        <div className="consent-links-checkbox-signup">
                            <input type="checkbox" className='custom-control custom-checkbox' onChange={(e) => handleCheckboxChangeInternal(e, 'terms_and_conditions')} />
                            {lang.iAgreeOn} <a href="/PrivacyPolicy#terms_and_conditions" target="_blank" rel="noreferrer"> {lang.termsAndConditionsTitle}</a>
                        </div>
                        <div className="consent-links-checkbox-signup">
                            <input type="checkbox" value={consent} className='custom-control custom-checkbox' onChange={(e) => handleCheckboxChangeInternal(e, 'consent')} />
                            {lang.iAgreeOn} <a href="/PrivacyPolicy#consent" target="_blank" rel="noreferrer"> {lang.consentTitle}</a>
                        </div>
                    </div>
                </div>
                   
                </form>
            </div> 
        </div>}
        </>
    );
}

