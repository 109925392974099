import React from 'react';
import { useInView } from 'react-intersection-observer';
import fileSolid from '../res/file-solid.svg';
import wpForms from '../res/wpforms.svg';
import squarePollHorizontalSolid from '../res/square-poll-horizontal-solid.svg';
import checkSolid from '../res/check-solid.svg';
import { useData } from './api/store'; // Import your custom hook for state management
import translations from './translations'; // Import translations
import './style/HowItWorks.css';

const HowItWorks = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { activeLang } = useData(); // Get the current language
  const lang = translations[activeLang]; // Get the translations for the active language

  return (
    <div 
      className={`section-how-it-works gray padding-top-65 padding-bottom-65 ${inView ? 'animate' : ''}`} 
      ref={ref}
      id="start"
    >
      <div className={`container ${activeLang === 'ar' ? 'flex-row-reverse' : ''}`}>
        <div className="row">
          <div className="col-xl-12">
            {/* Section Headline */}
            <div className="section-headline centered margin-top-0 margin-bottom-5">
              <h3 style={{ fontWeight: 'bold' }}>{lang.howItWorksTitle}</h3>
            </div>
          </div>

          <div className="col-xl-4 col-md-4">
            {/* Icon Box */}
            <div className={`icon-box with-line ${inView ? 'animate' : ''}`}>
              {/* Icon */}
              <div className={`icon-box-circle ${inView ? 'animate' : ''}`}>
                <div className={`icon-box-circle-inner ${inView ? 'animate' : ''}`}>
                  <img src={fileSolid} alt="Upload Icon" />
                  <div className={`icon-box-check ${inView ? 'animate' : ''}`}>
                    <img src={checkSolid} alt="Check Icon" />
                  </div>
                </div>
              </div>
              <h3>{lang.uploadLabTest}</h3>
              <p>{lang.uploadLabTestDescription}</p>
            </div>
          </div>

          <div className="col-xl-4 col-md-4">
            {/* Icon Box */}
            <div className={`icon-box with-line ${inView ? 'animate' : ''}`}>
              {/* Icon */}
              <div className={`icon-box-circle ${inView ? 'animate' : ''}`}>
                <div className={`icon-box-circle-inner ${inView ? 'animate' : ''}`}>
                  <img src={wpForms} alt="Form Icon" />
                  <div className={`icon-box-check ${inView ? 'animate' : ''}`}>
                    <img src={checkSolid} alt="Check Icon" />
                  </div>
                </div>
              </div>
              <h3>{lang.answerQuestionnaire}</h3>
              <p>{lang.answerQuestionnaireDescription}</p>
            </div>
          </div>

          <div className="col-xl-4 col-md-4">
            {/* Icon Box */}
            <div className={`icon-box ${inView ? 'animate' : ''}`}>
              {/* Icon */}
              <div className={`icon-box-circle ${inView ? 'animate' : ''}`}>
                <div className={`icon-box-circle-inner ${inView ? 'animate' : ''}`}>
                  <img src={squarePollHorizontalSolid} alt="Result Icon" />
                  <div className={`icon-box-check ${inView ? 'animate' : ''}`}>
                    <img src={checkSolid} alt="Check Icon" />
                  </div>
                </div>
              </div>
              <h3>{lang.getResult}</h3>
              <p>{lang.getResultDescription}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
