// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    height: 100%;
    background-color: #003c48;
    color: #fff;
    padding: 40px 20px;
  }
  
  .footer-container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 15%;
    padding-bottom: 20px;
    border-bottom: 1px solid #fff;
  }
  
  .footer-section {
    margin-bottom: 20px;
  }
  
  .footer-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .footer-links {
    list-style: none;
    padding: 0;
  }
  
  .footer-links li {
    margin-bottom: 8px;
  }
  
  .footer-links a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  
  .footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
  }
  
  .footer-social-icons a {
    color: #fff;
    font-size: 24px;
    margin-right: 10px;
  }
  
  .footer-social-icons a:hover {
    color: #1d72b8;
  }
  
  /* Media Queries */
  @media (min-width: 768px) {
    .footer-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/component/style/Footer.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,yBAAyB;IACzB,WAAW;IACX,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,qCAAqC;IACrC,SAAS;IACT,eAAe;IACf,oBAAoB;IACpB,6BAA6B;EAC/B;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,UAAU;EACZ;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,cAAc;EAChB;;EAEA,kBAAkB;EAClB;IACE;MACE,qCAAqC;IACvC;EACF","sourcesContent":[".footer {\n    height: 100%;\n    background-color: #003c48;\n    color: #fff;\n    padding: 40px 20px;\n  }\n  \n  .footer-container {\n    max-width: 1200px;\n    margin: 0 auto;\n  }\n  \n  .footer-grid {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    gap: 20px;\n    margin-top: 15%;\n    padding-bottom: 20px;\n    border-bottom: 1px solid #fff;\n  }\n  \n  .footer-section {\n    margin-bottom: 20px;\n  }\n  \n  .footer-title {\n    font-size: 18px;\n    font-weight: 600;\n    margin-bottom: 10px;\n  }\n  \n  .footer-links {\n    list-style: none;\n    padding: 0;\n  }\n  \n  .footer-links li {\n    margin-bottom: 8px;\n  }\n  \n  .footer-links a {\n    color: #fff;\n    text-decoration: none;\n  }\n  \n  .footer-links a:hover {\n    text-decoration: underline;\n  }\n  \n  .footer-bottom {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding-top: 20px;\n  }\n  \n  .footer-social-icons a {\n    color: #fff;\n    font-size: 24px;\n    margin-right: 10px;\n  }\n  \n  .footer-social-icons a:hover {\n    color: #1d72b8;\n  }\n  \n  /* Media Queries */\n  @media (min-width: 768px) {\n    .footer-grid {\n      grid-template-columns: repeat(4, 1fr);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
