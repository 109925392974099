import React, { useState, useEffect } from 'react';
import { SignUp } from './SignUp';
import { Login } from './Login';
import { useData } from '../api/store';
import { useNavigate } from 'react-router-dom';
import { checkIsLogin } from './checkIsLogin';
import SignLoginMobile from './SignLoginMobile';
import translations from '../translations'
import toast from 'react-hot-toast';
import '../style/Sign-login.css';
import '@fortawesome/fontawesome-free/css/all.css';

function SignLogin() {
  const {setMsg , isSignUpActive, setSignUpActive , setLoggedIn , otpShow , setEmail , activeLang} = useData();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const lang = translations[activeLang];

  useEffect(() => {
    const checkLoginStatus = async () => {
      const isLoggedIn = await checkIsLogin();
      if (isLoggedIn) {
        toast.success("You are already logged in.");
        navigate('/LandingPage');
      }
    };
    checkLoginStatus();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [navigate, setMsg]);

  const handleSignInClick = () => {
    setSignUpActive(false);
    setEmail('');
  };

  const handleSignUpClick = () => {
    setSignUpActive(true);
    setEmail('');
  };

  if (isMobile) {
    return <SignLoginMobile />;
  }

  return (
    <div className='parent-container-sign-up-in'>
      <div className={`container-sign-up-in ${isSignUpActive ? 'active-panel' : ''}`} id="container">
        <div className={`form-container sign-up ${isSignUpActive && 'right-panel-active'}`}>
          <SignUp />
        </div>
        <div className={`form-container sign-in ${!isSignUpActive && 'right-panel-active'}`}>
          <Login />
        </div>
        <div className="toggle-container">
          <div className="toggle">
            <div className="toggle-panel toggle-left">
              <h1>{lang.helloFreind}</h1>
              <p className='text-onSide'>{lang.helloFreindParaghraph}</p>
              <button className="sign-in-up-btn hidden" id="login" onClick={handleSignInClick} disabled={otpShow}>{lang.signIn}</button>
            </div>
            <div className="toggle-panel toggle-right">
              <h1>{lang.welcomeBack}</h1>
              <p className='text-onSide'>{lang.welcomeBackParagraph}</p>
              <button className="sign-in-up-btn hidden" id="register" onClick={handleSignUpClick} disabled={otpShow}>{lang.signUp}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignLogin;
