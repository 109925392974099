import { Outlet } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import Navbar from "./Navbar";
const Layout = () => {
  return (
    <div className="App">
       <Toaster 
       toastOptions={{
        duration: 8000,
        success: {
          style: {
            backgroundColor: 'green',
            color: 'white',
            zIndex: 9999,
          },
        },
        error: {
          style: {
            backgroundColor: '#e10101',
            color: 'white',
            zIndex: 9999,
          },
        },
        custom: {
          style: {
            backgroundColor: 'yellow',
            color: 'black',
            zIndex: 9999,
          },
        },

      }}
       />
        <Navbar />
        <Outlet />
    </div>
  );
};

export default Layout;