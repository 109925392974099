import React from 'react';
import FormLoading from '../Questionnaire/FormLoading';
import summaryImage from '../../res/summary.png'
import lineSegment from '../../res/line-segment.png'
import { useData } from '../api/store';
import translations from '../translations';

const Summary = ({ summary }) => {
    const { activeLang } = useData();
    const lang = translations[activeLang];
  return (
    <div className={`container summary ${activeLang === 'ar' ? 'flex-row-reverse' : ''}`}>
        {typeof summary === 'string' && summary ?
        <div className={`row ${activeLang === 'ar' ? 'onlyRtl' : ''}`}>
            <div className='col-lg-12'>
                <h1>{lang.summary}</h1>
            </div>
            <div className='col-lg-9'>
                <p>{summary.split('\n'||'\r'||'\r\n').map((line, index) => (
                <React.Fragment key={index}>
                    {line}
                    <br />
                </React.Fragment>
                ))}</p>
            </div>
           <div className='col-lg-3'>
                <img src={summaryImage} className='summary-image' />
                <img src={lineSegment} className='lineSegment-icon-summary' />
            </div>
        </div>
            : <FormLoading />
            }
    </div>
  );
}

export default Summary;
