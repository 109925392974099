export const validCountries = {
    en : [
    'Afghanistan', 'Aland Islands', 'Albania', 'Algeria', 'American Samoa', 'Andorra', 
    'Angola', 'Anguilla', 'Antarctica', 'Antigua and Barbuda', 'Argentina', 'Armenia', 
    'Aruba', 'Australia', 'Austria', 'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh', 
    'Barbados', 'Belarus', 'Belgium', 'Belize', 'Benin', 'Bermuda', 'Bhutan', 
    'Bolivia (Plurinational State of)', 'Bonaire, Sint Eustatius and Saba', 
    'Bosnia and Herzegovina', 'Botswana', 'Bouvet Island', 'Brazil', 
    'British Indian Ocean Territory', 'Brunei Darussalam', 'Bulgaria', 
    'Burkina Faso', 'Burundi', 'Cabo Verde', 'Cambodia', 'Cameroon', 'Canada', 
    'Cayman Islands', 'Central African Republic', 'Chad', 'Chile', 'China', 
    'Christmas Island', 'Cocos (Keeling) Islands', 'Colombia', 'Comoros', 'Congo', 
    'Congo (Democratic Republic of the)', 'Cook Islands', 'Costa Rica', 'Croatia', 
    'Cuba', 'Curaçao', 'Cyprus', 'Czech Republic', 'Denmark', 'Djibouti', 'Dominica', 
    'Dominican Republic', 'Ecuador', 'Egypt', 'El Salvador', 'Equatorial Guinea', 
    'Eritrea', 'Estonia', 'Eswatini', 'Ethiopia', 'Falkland Islands (Malvinas)', 
    'Faroe Islands', 'Fiji', 'Finland', 'France', 'French Guiana', 'French Polynesia', 
    'French Southern Territories', 'Gabon', 'Gambia', 'Georgia', 'Germany', 'Ghana', 
    'Gibraltar', 'Greece', 'Greenland', 'Grenada', 'Guadeloupe', 'Guam', 'Guatemala', 
    'Guernsey', 'Guinea', 'Guinea-Bissau', 'Guyana', 'Haiti', 'Heard Island and McDonald Islands', 
    'Holy See', 'Honduras', 'Hong Kong', 'Hungary', 'Iceland', 'India', 'Indonesia', 
    'Iran (Islamic Republic of)', 'Iraq', 'Ireland', 'Isle of Man', 'Israel', 'Italy', 
    'Jamaica', 'Japan', 'Jersey', 'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati', 
    "Korea (Democratic People's Republic of)", 'Korea (Republic of)', 'Kuwait', 
    'Kyrgyzstan', 'Lao People\'s Democratic Republic', 'Latvia', 'Lebanon', 'Lesotho', 
    'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Macao', 'Madagascar', 
    'Malawi', 'Malaysia', 'Maldives', 'Mali', 'Malta', 'Marshall Islands', 'Martinique', 
    'Mauritania', 'Mauritius', 'Mayotte', 'Mexico', 'Micronesia (Federated States of)', 
    'Moldova (Republic of)', 'Monaco', 'Mongolia', 'Montenegro', 'Montserrat', 'Morocco', 
    'Mozambique', 'Myanmar', 'Namibia', 'Nauru', 'Nepal', 'Netherlands', 'New Caledonia', 
    'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'Niue', 'Norfolk Island', 'North Macedonia', 
    'Northern Mariana Islands', 'Norway', 'Oman', 'Pakistan', 'Palau', 'Palestine, State of', 
    'Panama', 'Papua New Guinea', 'Paraguay', 'Peru', 'Philippines', 'Pitcairn', 'Poland', 
    'Portugal', 'Puerto Rico', 'Qatar', 'Réunion', 'Romania', 'Russian Federation', 'Rwanda', 
    'Saint Barthélemy', 'Saint Helena, Ascension and Tristan da Cunha', 'Saint Kitts and Nevis', 
    'Saint Lucia', 'Saint Martin (French part)', 'Saint Pierre and Miquelon', 'Saint Vincent and the Grenadines', 
    'Samoa', 'San Marino', 'Sao Tome and Principe', 'Saudi Arabia', 'Senegal', 'Serbia', 
    'Seychelles', 'Sierra Leone', 'Singapore', 'Sint Maarten (Dutch part)', 'Slovakia', 
    'Slovenia', 'Solomon Islands', 'Somalia', 'South Africa', 'South Georgia and the South Sandwich Islands', 
    'South Sudan', 'Spain', 'Sri Lanka', 'Sudan', 'Suriname', 'Svalbard and Jan Mayen', 
    'Sweden', 'Switzerland', 'Syrian Arab Republic', 'Taiwan, Province of China', 'Tajikistan', 
    'Tanzania, United Republic of', 'Thailand', 'Timor-Leste', 'Togo', 'Tokelau', 'Tonga', 
    'Trinidad and Tobago', 'Tunisia', 'Turkey', 'Turkmenistan', 'Turks and Caicos Islands', 
    'Tuvalu', 'Uganda', 'Ukraine', 'United Arab Emirates', 'United Kingdom of Great Britain and Northern Ireland', 
    'United States of America', 'Uruguay', 'Uzbekistan', 'Vanuatu', 'Venezuela (Bolivarian Republic of)', 
    'Viet Nam', 'Western Sahara', 'Yemen', 'Zambia', 'Zimbabwe'
    ],
    ar : [
      'أفغانستان', 'جزر آلاند', 'ألبانيا', 'الجزائر', 'ساموا الأمريكية', 'أندورا',
  'أنغولا', 'أنغيلا', 'أنتاركتيكا', 'أنتيغوا وبربودا', 'الأرجنتين', 'أرمينيا',
  'أروبا', 'أستراليا', 'النمسا', 'أذربيجان', 'الباهاماس', 'البحرين', 'بنغلاديش',
  'بربادوس', 'بيلاروسيا', 'بلجيكا', 'بليز', 'بنين', 'برمودا', 'بوتان',
  'بوليفيا (دولة بوليفيا المتعددة القوميات)', 'بونير وسانت يوستاتيوس وسابا',
  'البوسنة والهرسك', 'بوتسوانا', 'جزيرة بوفيت', 'البرازيل',
  'إقليم المحيط الهندي البريطاني', 'بروناي دار السلام', 'بلغاريا',
  'بوركينا فاسو', 'بوروندي', 'الرأس الأخضر', 'كمبوديا', 'الكاميرون', 'كندا',
  'جزر كايمان', 'جمهورية أفريقيا الوسطى', 'تشاد', 'تشيلي', 'الصين',
  'جزيرة الكريسماس', 'جزر كوكوس (كيلينغ)', 'كولومبيا', 'جزر القمر', 'الكونغو',
  'جمهورية الكونغو الديمقراطية', 'جزر كوك', 'كوستاريكا', 'كرواتيا',
  'كوبا', 'كوراساو', 'قبرص', 'جمهورية التشيك', 'الدانمارك', 'جيبوتي', 'دومينيكا',
  'جمهورية الدومينيكان', 'الإكوادور', 'مصر', 'السلفادور', 'غينيا الاستوائية',
  'إريتريا', 'إستونيا', 'إسواتيني', 'إثيوبيا', 'جزر فوكلاند (مالفيناس)',
  'جزر فارو', 'فيجي', 'فنلندا', 'فرنسا', 'غيانا الفرنسية', 'بولينيزيا الفرنسية',
  'المناطق الجنوبية الفرنسية', 'الغابون', 'غامبيا', 'جورجيا', 'ألمانيا', 'غانا',
  'جبل طارق', 'اليونان', 'جرينلاند', 'غرينادا', 'جوادلوب', 'غوام', 'غواتيمالا',
  'غيرنزي', 'غينيا', 'غينيا بيساو', 'غيانا', 'هايتي', 'جزيرة هيرد وجزر ماكدونالد',
  'الكرسي الرسولي', 'هندوراس', 'هونغ كونغ', 'المجر', 'آيسلندا', 'الهند', 'إندونيسيا',
  'إيران (جمهورية - الإسلامية)', 'العراق', 'أيرلندا', 'جزيرة مان', 'إسرائيل', 'إيطاليا',
  'جامايكا', 'اليابان', 'جيرزي', 'الأردن', 'كازاخستان', 'كينيا', 'كيريباس',
  'كوريا (جمهورية - الديمقراطية الشعبية)', 'كوريا (الجمهورية)', 'الكويت',
  'قيرغيزستان', 'جمهورية لاو الديمقراطية الشعبية', 'لاتفيا', 'لبنان', 'ليسوتو',
  'ليبيريا', 'ليبيا', 'ليختنشتاين', 'ليتوانيا', 'لوكسمبورغ', 'ماكاو', 'مدغشقر',
  'مالاوي', 'ماليزيا', 'جزر المالديف', 'مالي', 'مالطا', 'جزر مارشال', 'مارتينيك',
  'موريتانيا', 'موريشيوس', 'مايوت', 'المكسيك', 'ولايات ميكرونيزيا المتحدة',
  'مولدوفا (جمهورية)', 'موناكو', 'منغوليا', 'الجبل الأسود', 'مونتسيرات', 'المغرب',
  'موزمبيق', 'ميانمار', 'ناميبيا', 'ناورو', 'نيبال', 'هولندا', 'كاليدونيا الجديدة',
  'نيوزيلندا', 'نيكاراغوا', 'النيجر', 'نيجيريا', 'نيوي', 'جزيرة نورفولك', 'شمال مقدونيا',
  'جزر ماريانا الشمالية', 'النرويج', 'عمان', 'باكستان', 'بالاو', 'دولة فلسطين',
  'بنما', 'بابوا غينيا الجديدة', 'باراغواي', 'بيرو', 'الفلبين', 'بيتكيرن', 'بولندا',
  'البرتغال', 'بورتوريكو', 'قطر', 'ريونيون', 'رومانيا', 'الاتحاد الروسي', 'رواندا',
  'سان بارتليمي', 'سانت هيلانة وأسنسيون وتريستان دا كونا', 'سانت كيتس ونيفيس',
  'سانت لوسيا', 'سانت مارتن (الجزء الفرنسي)', 'سانت بيير وميكلون', 'سانت فنسنت وجزر غرينادين',
  'ساموا', 'سان مارينو', 'ساو تومي وبرينسيبي', 'المملكة العربية السعودية', 'السنغال', 'صربيا',
  'سيشل', 'سيراليون', 'سنغافورة', 'سينت مارتن (الجزء الهولندي)', 'سلوفاكيا',
  'سلوفينيا', 'جزر سليمان', 'الصومال', 'جنوب أفريقيا', 'جورجيا الجنوبية وجزر ساندويتش الجنوبية',
  'جنوب السودان', 'إسبانيا', 'سريلانكا', 'السودان', 'سورينام', 'سفالبارد ويان ماين',
  'السويد', 'سويسرا', 'الجمهورية العربية السورية', 'تايوان، مقاطعة الصين', 'طاجيكستان',
  'تنزانيا، جمهورية', 'تايلاند', 'تيمور الشرقية', 'توغو', 'توكيلاو', 'تونغا',
  'ترينيداد وتوباغو', 'تونس', 'تركيا', 'تركمانستان', 'جزر تركس وكايكوس',
  'توفالو', 'أوغندا', 'أوكرانيا', 'الإمارات العربية المتحدة', 'المملكة المتحدة لبريطانيا العظمى وأيرلندا الشمالية',
  'الولايات المتحدة الأمريكية', 'أوروغواي', 'أوزبكستان', 'فانواتو', 'فنزويلا (جمهورية - البوليفارية)',
  'فيتنام', 'الصحراء الغربية', 'اليمن', 'زامبيا', 'زيمبابوي'
  ]
};
  