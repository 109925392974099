import React, { useState, useEffect, useRef } from 'react';
import Confetti from "./magicui/confetti";
import { useData } from '../api/store';
import translations from '../translations';

const CategoryCompletedPopup = ({ categoryName }) => {
  const confettiRef = useRef(null);
  const [showPopup, setShowPopup] = useState(true);
  const { activeLang } = useData();
  const lang = translations[activeLang];

  useEffect(() => {
    if (showPopup && confettiRef.current) {
      confettiRef.current.fire({});
    }

    const timer = setTimeout(() => {
      setShowPopup(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [showPopup]);

  return (
    <>
      <Confetti ref={confettiRef} className="Confetti" />
      <div className={`category-completed-popup ${showPopup ? 'show' : ''}`}>
        <div className="popup-content">
          <span className="celebration-icon"> 🎉 </span>
          <p>{lang.congratsTitle}{categoryName} {lang.category}</p>
          <br></br>
          <p>{lang.congratsText}</p>
        </div>
      </div>
    </>
  );
};

export default CategoryCompletedPopup;
