import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import translations from "../translations";
import { useData } from "../api/store";

const DELETE_URL = process.env.REACT_APP_BASE_URL + "/api/delete_user/";

const DeleteModal = ({ setModalDeleteConsent }) => {
  const [consented, setConsented] = useState(false);
  const { setLoggedIn, setUploadedFiles, toggleSettings , setConsent , deleteConsent , deleteConsent_ar, activeLang} = useData();
  const lang = translations[activeLang];
  const navigate = useNavigate();

  const handleDeleteConsent = async () => {
    if (consented) {
      try {
        const response = await axios.post(DELETE_URL, { 
            consented: "True" 
          },{
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        });

        if (response.status === 202) {
          toast.success("Account deletion process initiated. Your account will be deleted in 7 days.");
          document.cookie.split(";").forEach((c) => {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
          });
          setConsent(false);
          setLoggedIn(false);
          setUploadedFiles([]);
          toggleSettings();
          navigate("/");
        } else if (response.status === 400) {
          toast.error("Bad request. Please try again.");
        } else {
          throw new Error("Error during delete");
        }
      } catch (error) {
        toast.error("Network Error, Try again later.");
      }
    } else {
      toast.error("Please check the consent checkbox");
    }
  };

  const renderWithLineBreaks = (text) => {
    if (text) {
    return text.split(/(?:\r\n|\r|\n)/g).map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));
    } else {
        return "No terms found, Please do not proceed further without accepting reading terms and conditions";
    }
};


  return (
    <div className={`delete-modal ${activeLang === 'ar' ? 'onlyRtl' : ''}`}>
      <div className={`delete-modal__content `}>
        <h3 className="delete-modal__title">{lang.deleteAccount}</h3>
        <div className='scrollable-content-sign-up'>
        {
          activeLang === 'ar' ?
          <p className="delete-modal__text" style={{textAlign : 'right'}}>{renderWithLineBreaks(deleteConsent_ar)}</p>
          : 
          <p className="delete-modal__text" style={{textAlign : 'left'}}>{renderWithLineBreaks(deleteConsent)}</p>
        }
        </div>
        <div className="delete-modal__consent">
          <input
            type="checkbox"
            id="consentCheckbox"
            checked={consented}
            onChange={() => setConsented(!consented)}
          />
          <label htmlFor="consentCheckbox">{lang.Agree}</label>
        </div>
        <div className="delete-modal__actions">
          <button
            onClick={handleDeleteConsent}
            className="delete-modal__button delete-modal__button--delete"
            disabled={!consented}
          >
            {lang.deleteAccountBtn}
          </button>
          <button
            onClick={() => setModalDeleteConsent(false)}
            className="delete-modal__button delete-modal__button--cancel"
          >
          {lang.cancel}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
