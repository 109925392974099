import React from 'react';
import FormLoading from '../Questionnaire/FormLoading';
import recommendImage from '../../res/recomendations.png';
import lineSegment from '../../res/line-segment.png';
import { useData } from '../api/store';
import translations from '../translations';

const Recommendations = ({ tips }) => {
  const { activeLang } = useData();
  const lang = translations[activeLang];
  return (
    <div className="container recommendations">
          {typeof tips === 'string' ?
      <div className='row'>
        <div className='col-lg-12'>
          <h1>{lang.tips}</h1>
        </div>
        <div className='col-lg-3'>
        <img src={recommendImage} className='recommendations-image' />
        <img src={lineSegment} className='lineSegment-icon-recommendations' />
        </div>
        <div className='col-lg-9'>
          {tips.split('\n'||'\r'||'\r\n').map((line , index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </div>
        </div>
          : <FormLoading />
          }
    </div>
  );
}

export default Recommendations;
