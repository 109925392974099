import React from 'react';
import { useState, useRef , useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { handleGoogleLogin } from './handleGoogleLogin';
import { handleFacebookLogin } from './handleFacebookLogin';
import { FacebookAuth }  from './FacebookAuth';
import { useData } from '../api/store';
import  OtpPopUp  from './OtpPopUp';
import { sendOtpLogin } from './sendOtpLogin';
import LoadingPage from '../LoadingPage';
import translations from '../translations'
import '../style/Sign-login.css';
import '@fortawesome/fontawesome-free/css/all.css';

export function Login() {
  const navigate = useNavigate();
  const {errMsg, 
    otpShow,
    email,
    successMsg,
    success,
    setErrMsg, 
    setValidEmail,
    loadingPage,
    setLoadingPage,
    isLoggedin,
    setConsent,
    setLoggedIn,
    consent,
    setEmail,
    activeLang} = useData();
    const EMAIL_REGX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const emailRef = useRef(null);
    const googleLoginRef = useRef(null);
    const lang = translations[activeLang];

    
    const handleSubmit = async (e) => {
      e.preventDefault();
      const validEmail = EMAIL_REGX.test(email);
      if (validEmail) {
        setLoadingPage(true);
        await sendOtpLogin(navigate);
        setLoadingPage(false);
      } else {
        setErrMsg('Please enter valid email');
      }
    }

  useEffect(() => {
    const result = EMAIL_REGX.test(email);
    setValidEmail(result);
  }, [email]);

  useEffect(() => {
    setErrMsg("");
  }, [email]);



  const handleGoogleLoginRequest = async (e) =>{
    if (googleLoginRef.current){
      googleLoginRef.current.click();
    }
  }
  const handleGoogleLoginSuccess = async (credentialResponse) => {
    setLoadingPage(true);
    await handleGoogleLogin(credentialResponse , navigate);
    setLoadingPage(false);
  }
  
  const handleGoogleLoginFailure = (error) => {
    setErrMsg('Google login failed');
  }

  const handleFacebookLoginSuccess = async (response) => {
    setLoadingPage(true);
    await handleFacebookLogin(response , navigate);
    setLoadingPage(false);
  }
  const handleFacebookLoginFailure = (error) => {
    setErrMsg('Facebook login failed');
  }
  return (
    <>
      {loadingPage && <LoadingPage />}
      {otpShow && <OtpPopUp SignType={'SignIn'}/>}
      {otpShow ?  <></> :
      <form>
        <p className={success ? 'successMsg ' : 'offScreen'}>{successMsg}</p>
        <p className={errMsg ? 'errMsg' : 'offScreen'}>{errMsg}</p>
        <h1>{lang.loginSignIntitle}</h1>
        <input 
          className='sign-up-input'
          type="email"
          ref={emailRef}
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)} 
        />
        <button className='sign-in-up-btn' onClick={handleSubmit}> {lang.loginSignInBtn} </button>
        <span>{lang.OrSignInwith}</span>
        <div className="social-icons">
          <FacebookAuth
           autoLoad={true}
           fields="name,email,picture" 
            onSuccess={handleFacebookLoginSuccess}
            onFailure={handleFacebookLoginFailure}
          />
          <div className="button-content">
            <GoogleLogin
                 onSuccess={handleGoogleLoginSuccess}
                 onFailure={handleGoogleLoginFailure}
                 text=""
                 theme="outline"
                 size="medium"
                 width="50"
                 shape="circle"
                 logo_alignment="center"
                />
          </div>
        </div>

      </form>}
    </>
  );
}
