import React from 'react';

const NotFound = () => {
    return (
       <>

<h2>Page Not Found</h2>
       </>
    );
};

export default NotFound;