// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/style/LanguageSwitcher.css */
.language-switcher {
  display: flex;
  justify-content: center;
  margin-right: 2rem;
}

.language-options {
  position: relative;
  display: flex;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  background-color: #e4e4e4;
}

.language-option {
  background: transparent;
  border: none;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  z-index: 1; /* Ensures the text is above the slider */
  position: relative;
  transition: color 0.3s ease;
}

.language-option.activelang {
  color: #01a4c0;
}

.language-option:not(.activelang):hover {
  color: #555;
}

.slider {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%; /* Adjust this based on how many options you have */
  background-color: #ffffff;
  border-radius: 20px;
  transition: transform 0.5s ease; /* Sliding effect */
}

.slide-en {
  transform: translateX(0); /* Position for the first option */
}

.slide-ar {
  transform: translateX(100%); /* Position for the second option */
}


@media (max-width: 768px){
  .language-switcher {
    margin-right: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/component/style/LanguageSwitcher.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;EACE,aAAa;EACb,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,sCAAsC;EACtC,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,eAAe;EACf,iBAAiB;EACjB,UAAU,EAAE,yCAAyC;EACrD,kBAAkB;EAClB,2BAA2B;AAC7B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,UAAU,EAAE,mDAAmD;EAC/D,yBAAyB;EACzB,mBAAmB;EACnB,+BAA+B,EAAE,mBAAmB;AACtD;;AAEA;EACE,wBAAwB,EAAE,kCAAkC;AAC9D;;AAEA;EACE,2BAA2B,EAAE,mCAAmC;AAClE;;;AAGA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":["/* src/style/LanguageSwitcher.css */\n.language-switcher {\n  display: flex;\n  justify-content: center;\n  margin-right: 2rem;\n}\n\n.language-options {\n  position: relative;\n  display: flex;\n  border-radius: 20px;\n  overflow: hidden;\n  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);\n  background-color: #e4e4e4;\n}\n\n.language-option {\n  background: transparent;\n  border: none;\n  padding: 6px 12px;\n  cursor: pointer;\n  font-size: 13px;\n  font-weight: bold;\n  z-index: 1; /* Ensures the text is above the slider */\n  position: relative;\n  transition: color 0.3s ease;\n}\n\n.language-option.activelang {\n  color: #01a4c0;\n}\n\n.language-option:not(.activelang):hover {\n  color: #555;\n}\n\n.slider {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  width: 50%; /* Adjust this based on how many options you have */\n  background-color: #ffffff;\n  border-radius: 20px;\n  transition: transform 0.5s ease; /* Sliding effect */\n}\n\n.slide-en {\n  transform: translateX(0); /* Position for the first option */\n}\n\n.slide-ar {\n  transform: translateX(100%); /* Position for the second option */\n}\n\n\n@media (max-width: 768px){\n  .language-switcher {\n    margin-right: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
