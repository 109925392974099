export const flattenQuestions = (data) => {
    if (!data || data.length < 1){
      window.location.reload();
      alert("Faled to get the questions");
      return null;
    }
    let allQuestions = [];
    Object.keys(data).forEach(category => {
      data[category].forEach(question => {
        allQuestions.push({ ...question, category });
      });
    });
    return allQuestions;
  };
  
  export const categoryIcons = {
    'gender': 'fa fa-vcard',
    'General Health Information': 'fa fa-heartbeat',
    'Medical History': 'fa fa-file-text',
    'Lifestyle and Wellbeing': 'fa fa-heart',
    'Extended Health History': 'fa fa-file-text',
    "Women's Health": 'fa fa-female'
  };
  