import {jwtDecode} from 'jwt-decode';
import { useData } from '../api/store';
import toast from 'react-hot-toast';
import { setAuthTokenCookie } from './setAuthTokenCookie';
import axios from 'axios';
const GET_USER_TOKEN = process.env.REACT_APP_BASE_URL + "/api/login_user/";

export async function handleGoogleLogin(credentialResponse , navigate) {
  const {signInForVisitor , setSignInForVisitor , setLoggedInUser , setSignUpActive} = useData.getState();
  let { credential } = credentialResponse;
  const decodedToken = jwtDecode(credential);
  const { email } = decodedToken;

  try {
    const response = await axios.post(GET_USER_TOKEN, {
      vendor: "google",
      email: email,
      access_token: credential,
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });
    if (response.status === 200 || response.status === 201) {
      setAuthTokenCookie(response.data.token); 
      setLoggedInUser({
        username : response.data.name,
        email : response.data.email,
      })
      toast.success("You logged in successfully 👍");
      if (signInForVisitor)
      {
        setSignInForVisitor(false);
        return ;
      } else {
        navigate(-1);
      }
    }
  } catch (error) {
    if (error.response.status === 404){
      setSignUpActive(true);
      toast.error('User not found, please sign up first.');
    }
    else
      toast.error('Network Error, Try again later.');
  }
}
