import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useData } from '../api/store';
import { handleUserSignUp } from './handleUserSignUp.js';
import { handleUserLogin } from './handleUserLogin.js';
import { sendOtp } from './sendOtp.js';
import '../style/OtpPopUp.css';

const OtpPopUp = ({ SignType }) => {
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [remainingTime, setRemainingTime] = useState(6 * 60);
  const otpRefs = useRef([]);
  const navigate = useNavigate();
  const { successMsg , setOtpShow , setConsent , setEmail , setUser} = useData();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (remainingTime > 0) {
        setRemainingTime((prevTime) => prevTime - 1);
      } else {
        setResendDisabled(false);
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [remainingTime]);

  const handleChange = (e, index) => {
    const { value } = e.target;
    if (value.length <= 1 && !isNaN(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < 5) {
        otpRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && !otp[index]) {
      otpRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const paste = e.clipboardData.getData('text');
    if (/^\d{6}$/.test(paste)) {
      const newOtp = paste.split('');
      setOtp(newOtp);
      otpRefs.current[newOtp.length - 1].focus();
    }
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    const otpString = otp.join('');
    if (SignType === 'SignUp') 
      handleUserSignUp(navigate, otpString);
    else if (SignType === 'SignIn') 
      handleUserLogin(navigate, otpString);
  };

  const handleResend = () => {
    sendOtp();
    setResendDisabled(true);
    setRemainingTime(6 * 60);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const handleClose = () => {
    setEmail('');
    setUser('');
    setOtpShow(false);
    setConsent(false);
  }

  return (
    <div className="otp-popup">
      <div className="otp-container">
        <h2>Enter OTP</h2>
        <i className="fa fa-times icon-close-otp" aria-hidden="true" onClick={handleClose}></i>
        {successMsg && <p className="alert alert-warning">{successMsg}</p>}
        <button className="resend-otp-btn" disabled={resendDisabled} onClick={handleResend}>
          {resendDisabled ? `Resend OTP in ${formatTime(remainingTime)}` : 'Resend OTP'}
        </button>
        <form onSubmit={handleVerify}>
          <div className="otp-input-container">
            {otp.map((digit, index) => (
              <input
                key={index}
                ref={(ref) => (otpRefs.current[index] = ref)}
                type="text"
                value={digit}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onPaste={handlePaste}
                maxLength="1"
                required
              />
            ))}
          </div>
          <button className="verify-btn" type="submit">Verify</button>
        </form>
      </div>
    </div>
  );
};

export default OtpPopUp;
