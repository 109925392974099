import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import './style/ContactUs.css';
import { AiOutlineSmallDash } from "react-icons/ai";
import LoadingPage from './LoadingPage';
import doctorImage from '../res/ContactUs.png';
import axios from './api/axios';
import DOMPurify from 'dompurify';
import MsgSentSuccess from './MsgSentSuccess';
import translations from './translations';
import { useData } from './api/store';

const CONTACT_US_URL = process.env.REACT_APP_BASE_URL + "/api/contact_us/";

const ContactUs = () => {
  const [err, setErr] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const EMAIL_REGX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });

  const { activeLang } = useData(); // Assuming this is where the language is stored
  const t = translations[activeLang]; // Get translations based on active language

  useEffect(() => {
    setErr("");
    setSuccessMsg("");
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const sanitizedEmail = DOMPurify.sanitize(formData.email);
    const sanitizedMessage = DOMPurify.sanitize(formData.message);
    const sanitizedName = DOMPurify.sanitize(formData.name);

    if (!sanitizedEmail.trim() || !sanitizedMessage.trim() || !sanitizedName.trim()) {
      toast.error(t.errMsgEmptyField);
      return;
    } else if (!EMAIL_REGX.test(sanitizedEmail)) {
      toast.error(t.errMsgInvalidEmail);
      return;
    } else if (sanitizedMessage.length > 500) {
      toast.error(t.errMsgLongMessage);
      return;
    } else {
      try {
        setIsLoading(true);
        const response = await axios.post(CONTACT_US_URL, {
          email: sanitizedEmail,
          body: sanitizedMessage,
        });
        if (response.status === 200) {
          setIsLoading(false);
          setIsSent(true);
        } else {
          throw new Error(t.errMsgSendFail);
        }
      } catch (error) {
        setIsLoading(false);
        setErr(t.errMsgSendFail);
        toast.error(t.errMsgSendFail);
      }
    }
  };

  return (
    <>
      {err && <p className='alert alert-danger fade-out' role='role'>{err}</p>}
      {successMsg && <p className='alert alert-success fade-out' role='role'>{successMsg}</p>}
      {isLoading && <LoadingPage />}
      <div className={`contact-us ${activeLang === 'ar' ? 'rtl' : ''}`}>
        {isSent ? <MsgSentSuccess /> :
          <div className="contact-us-content">
            <h1>{t.title}</h1>
            <AiOutlineSmallDash className='contact-us-dots'/>
            <form className="contact-form" onSubmit={handleSubmit} noValidate>
              <input 
                type="text" 
                name="name" 
                placeholder={t.namePlaceholder} 
                value={formData.name} 
                onChange={handleChange} 
              />
              <input 
                type="email" 
                name="email" 
                placeholder={t.emailPlaceholder} 
                value={formData.email} 
                onChange={handleChange} 
              />
              <textarea 
                name="message" 
                placeholder={t.messagePlaceholder} 
                value={formData.message} 
                onChange={handleChange} 
              />
              <button type="submit" id="button">{t.buttonText}</button>
            </form>
          </div>
        }
        <div className="contact-us-image">
          <img src={doctorImage} alt="Doctor" loading="lazy"/>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
