import React from 'react';

export const TextInput = ({ question, currentAnswer, specifyOptions, currentQuestionIndex }) => {
  
  const { inputText , handleInutChange } = specifyOptions;
  
  return (
    <div key={question.id} className='fade-in'>
      <label className='label-q fade-in'>{question.text}</label>
      <input 
        type="text" 
        name="answer" 
        defaultValue={inputText}
        onChange={handleInutChange}
      />
    </div>
  );
};
