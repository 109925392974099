// src/LanguageSwitcher.js
import React from 'react';
import { useData } from './api/store';
import './style/LanguageSwitcher.css';

function LanguageSwitcher() {
  const { activeLang, setActiveLang } = useData();

  const handleLanguageChange = (lang) => {
    setActiveLang(lang);
  };

  return (
    <div className="language-switcher">
      <div className="language-options">
      <div className={`slider ${activeLang === 'en' ? 'slide-en' : 'slide-ar'}`}></div>
        <button
          className={`language-option ${activeLang === 'en' ? 'activelang' : ''}`}
          onClick={() => handleLanguageChange('en')}
        >
          English
        </button>
        <button
          className={`language-option ${activeLang === 'ar' ? 'activelang' : ''}`}
          onClick={() => handleLanguageChange('ar')}
        >
          عربي
        </button>
      </div>
    </div>
  );
}

export default LanguageSwitcher;
