import React, { useState } from 'react';
import SaveButton from './SaveButton';
import { useData } from '../api/store';
import translations from '../translations';

const General = () => {
  const { subscribed , setSubscribed , activeLang} = useData();
  const lang = translations[activeLang];


  const handleSubscribeChange = (e) => {
    setSubscribed(e.target.checked);
  };

  return (
    <div className="settings-content">
      <h2>{lang.PreferencesTitle}</h2>
      <div className="form-group form-group-general">
        <input 
          type="checkbox" 
          id="product-updates" 
          checked={subscribed} 
          onChange={handleSubscribeChange}
        />
        <label htmlFor="product-updates"> {lang.PreferencesOpt_1} </label>
      </div>
      <SaveButton
        language=''
        birthday=''
        country=''
        subscribed={subscribed ? 'True' : 'False'}
        name=''
      />
    </div>
  );
};

export default General;
