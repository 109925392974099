import { useData } from '../api/store';
import axios from 'axios';
import toast from 'react-hot-toast';
import { setAuthTokenCookie } from './setAuthTokenCookie';
const GET_USER_TOKEN = process.env.REACT_APP_BASE_URL + "/api/login_user/";

export async function handleFacebookLogin( response , navigate) {
    const {setSignUpActive, setLoggedInUser ,setSignInForVisitor , signInForVisitor} = useData.getState();
    const email = response.email;
    const token = response.accessToken
    try {
      const response = await axios.post(GET_USER_TOKEN, { 
        vendor: "facebook",
        email: email,
        access_token: token,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });
      if (response.status === 200 || response.status === 201) {
        setAuthTokenCookie(response.data.token);
        setLoggedInUser({
          username : response.data.name,
          email : response.data.email,
        })
        toast.success('You logged in successfully')
        if (signInForVisitor)
          {
            setSignInForVisitor(false);
            return ;
          } else {
              navigate(-1);
          }
      }
    } catch (error) {
      if (error.response.status === 404){
        setSignUpActive(true);
        toast.error('User not found, please sign up first.');
      } else {
        toast.error('Network error. Please try again.');
      }
      return;
    }
}