import React, { useState, useEffect , useRef} from 'react';
import { useData } from '../api/store';
import { flattenQuestions, categoryIcons } from './helpers';
import { Progress } from './Progress';
import { Question } from './Question';
import LoadingResults from './LoadingResults';
import LoadingSpinner from './FormLoading';
import { uploadingQAandFile } from './uploadingQAandFile';
import { questionnaireParser } from './questionnaireParser';
import SignLogin from '../Authentication/Sign-login';
import  CategoryCompletedPopup  from './CategoryCompletedPopup';
import questionsData from './en-questions.json';
import questionsData_ar from './ar-questions.json';
import UploadButton from '../UploadButton';
import translations from '../translations'
import '../style/questionnaire.css';
import '@fortawesome/fontawesome-free/css/all.css';
import { useNavigate } from 'react-router-dom';

export function Questionnaire({ files, navigate , setFiles}) {
  const { errMsg, setErrMsg, loading , signInForVisitor , setSignInForVisitor , setIsFileUploaded, activeLang } = useData();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [questionnaireCompleted, setQuestionnaireCompleted] = useState(false);
  const [animateProgress, setAnimateProgress] = useState(false);
  const [answers, setAnswers] = useState({});
  const [answersInCategory, setAnswersInCategory] = useState({});
  const [otherText, setOtherText] = useState({});
  const [foodText, setFoodText] = useState({});
  const [inputText, setInputText] = useState({});
  const [medicationsText, setMedicationsText] = useState({});
  const [isFemale, setIsFemale] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [completedCategory, setCompletedCategory] = useState(null);
  const lang = translations[activeLang];

  useEffect(() => {
    if (currentQuestionIndex > 0 && currentQuestionIndex < filteredQuestions.length && filteredQuestions[currentQuestionIndex].category !== filteredQuestions[currentQuestionIndex - 1].category) {
      setCompletedCategory(filteredQuestions[currentQuestionIndex - 1].category);
      setTimeout(() => {
        setCompletedCategory(null);
      }, 2000);
    }
  }, [currentQuestionIndex]);

  useEffect(() => {
    const nextButton = document.getElementById("next-btn");
    if (nextButton) {
      nextButton.focus();
    }
  }, [answers , currentQuestionIndex]);


  useEffect(() => {
    const handlePopState = () => {
      console.log("Back button clicked");
      setIsFileUploaded(false);
      setSignInForVisitor(false);
      setFiles([]);
      navigate("/LandingPage");
    };
  
    window.addEventListener('popstate', handlePopState);
  
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate, setFiles, setIsFileUploaded, setSignInForVisitor]); // Added all dependencies
  

  useEffect(() => {
    
      const getQuestions = () =>{
        if (activeLang === 'en')
            setQuestions(flattenQuestions(questionsData));
        else if (activeLang === 'ar')
            setQuestions(flattenQuestions(questionsData_ar));
        else {
          setErrMsg('Unsupported language');
        }
      }
      getQuestions();
  }, [setErrMsg]);

  useEffect(() => {
    const filterQuestionsByGender = (questions, isFemale) => {
      if (isFemale) 
        return questions;
      else
        return questions.filter(question => question.category !== "Women's Health");
    };

    setFilteredQuestions(filterQuestionsByGender(questions, isFemale));
  }, [questions, isFemale]);

  useEffect(() => {
    setErrMsg('');
    if (currentQuestionIndex > filteredQuestions.length - 1) {
      setQuestionnaireCompleted(true);
    } else {
      setQuestionnaireCompleted(false);
    }
  }, [currentQuestionIndex, filteredQuestions.length]);

  const handleAnswer = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const answer = formData.getAll('answer');
    const currentQuestion = filteredQuestions[currentQuestionIndex];
  
    setFormLoading(true);
    if (questionnaireParser(currentQuestion, answer, setErrMsg) === false) {
      setFormLoading(false);
      return;
    }
  
    const category = currentQuestion.category;
    const { id, label, text, type } = currentQuestion;
  
    const newAnswer = {
      id,
      label,
      text,
      type,
      options: [...new Set(answer)],
    };
  
    if (currentQuestion.category === 'gender') {
      if (answer.includes('Female') || answer.includes('انثي')) 
        setIsFemale(true);
      else if (answer.includes('Male') || answer.includes('ذكر')) 
        setIsFemale(false);
      else {
        setErrMsg('Not a valid choice');
        setFormLoading(false);
        return;
      }
    }
  
    setAnswersInCategory((prevAnswers) => {
      const updatedAnswers = { ...prevAnswers };
      
      if (category) {
        if (!updatedAnswers[category]) {
          updatedAnswers[category] = [];
        }
          updatedAnswers[category] = updatedAnswers[category].filter(a => a.id !== id);
    
        updatedAnswers[category].push(newAnswer);
      }
    
      return updatedAnswers;
    });
  
    setAnimateProgress(true);
    setTimeout(() => {
      setAnimateProgress(false);
      if (currentQuestionIndex <= filteredQuestions.length - 1) {
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      }
      setFormLoading(false);
      form.reset();
    }, 300);
  };
  
  

  const handleBack = () => {
    if (currentQuestionIndex > 0) {
      setAnimateProgress(true);
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleOtherChange = (e) => {
    setOtherText((prev) => ({ ...prev, [currentQuestionIndex]: e.target.value }));
  };

  const handleFoodChange = (e) => {
    setFoodText((prev) => ({ ...prev, [currentQuestionIndex]: e.target.value }));
  };

  const handleMedicationsChange = (e) => {
    setMedicationsText((prev) => ({ ...prev, [currentQuestionIndex]: e.target.value }));
  };

  const handleInputText = (e) => {
    setInputText((prev) => ({ ...prev, [currentQuestionIndex]: e.target.value }));
  };

  const specifyOptions = { 
    handleInputText, 
    inputText: inputText[currentQuestionIndex] || '', 
    otherText: otherText[currentQuestionIndex] || '', 
    handleOtherChange, 
    foodText: foodText[currentQuestionIndex] || '', 
    handleFoodChange, 
    medicationsText: medicationsText[currentQuestionIndex] || '', 
    handleMedicationsChange 
  };

  const handleSubmit = () => {
    uploadingQAandFile(files, answersInCategory, setErrMsg , navigate , setCurrentQuestionIndex);
  };

  const handleClose = () => {
    window.location.reload();
  };

  if (!questions || questions.length === 0) {
    return <LoadingResults />;
  }

  const currentCategory = filteredQuestions[currentQuestionIndex]?.category;
  const categoryIconClass = categoryIcons[currentCategory];

  const translatedTitle = (categoryTitle) =>{
    if (activeLang === 'ar'){
      if (categoryTitle === 'gender')
        return 'النوع';
      else if (categoryTitle === 'General Health Information')
        return 'معلومات صحية عامة';
      else if (categoryTitle === 'Medical History')
        return 'التاريخ الطبي';
      else if (categoryTitle === 'Lifestyle and Wellbeing')
        return 'نمط الحياة';
      else if (categoryTitle === "Women's Health")
        return 'صحة المرأة';
      else if (categoryTitle === 'Extended Health History')
        return 'التاريخ الطبي (مفصل)';
      else 
        return "";
    } else{
      return categoryTitle; 
    }
  }

  return (
    <>
      {signInForVisitor ? <SignLogin /> :
      <>
      {loading ? <LoadingResults /> :
        <>
        <div className='questionnaire-bg'>
          <div className="questionnaire-header">
            <h1 className="questionnaire-title">{lang.Questionnaire}</h1>
          </div>
          <div className="container">
            <div className="centered-questionnaire">
              <Progress
                currentQuestionIndex={currentQuestionIndex}
                totalQuestions={filteredQuestions.length}
                animateProgress={animateProgress}
                questionnaireCompleted={questionnaireCompleted}
              />
              {formLoading && <LoadingSpinner />}
              {!formLoading &&
                <form className={`questionnaire-form ${activeLang === 'ar' ? 'onlyRtl' : ''}`} data-testid="questionnaire" onSubmit={handleAnswer}>
                  <i className="fa fa-window-close icon-close-questionnaire" aria-hidden="true" onClick={handleClose}></i>
                  {errMsg && <div className="error-message alert alert-danger fade-out">{errMsg}</div>}
                  <div className="header fade-in">
                    <h2>{(translatedTitle(currentCategory))}</h2>
                    <div className={`category-icon ${categoryIconClass}`}></div>
                  </div>
                  {currentQuestionIndex > 0 && !questionnaireCompleted && <p className='fade-in'>{lang.questionsNum} {currentQuestionIndex + 1} {lang.questionsNumOf} {filteredQuestions.length}</p>}
                  {completedCategory && <CategoryCompletedPopup categoryName={completedCategory} />}
                  {!completedCategory && <Question
                    question={filteredQuestions[currentQuestionIndex]}
                    currentAnswer={answers[currentQuestionIndex] || []}
                    setAnswers={setAnswers}
                    currentQuestionIndex={currentQuestionIndex}
                    specifyOptions={specifyOptions}
                  />}
                  {questionnaireCompleted &&
                    <div className='questions-info'>
                      <h2>{lang.questionsInfoH2}</h2>
                      <p>
                      {lang.questionsInfoP}
                      </p>
                      <span>{lang.questionsInfoSpan}</span>
                    </div>
                  }
                  <br />
                  <div className={`${activeLang === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                  {!completedCategory &&<button className="backbtn fade-in" type="button" onClick={handleBack}>{lang.Q_backbtn}</button>}
                  {!questionnaireCompleted && !completedCategory && <button className="nextbtn fade-in" id="next-btn" type="submit" disabled={questionnaireCompleted} autoFocus>{lang.Q_nextbtn}</button>}
                  {questionnaireCompleted && <button className="nextbtn" type="button" onClick={handleSubmit}>{lang.Q_submitbtn}</button>}
                  </div>
                </form>}
                {/* <UploadButton setFiles={setFiles} /> */}
            </div>
          </div>
          </div>
        </>
      }
      </>
      }
    </>
  );
}
