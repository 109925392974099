import React, { useState, useEffect, useRef } from "react";
import { useData } from "../api/store";
import { flattenQuestions, categoryIcons } from "./helpers";
import { Progress } from "./Progress";
import { Question } from "./Question";
import LoadingResults from "./LoadingResults";
import LoadingSpinner from "./FormLoading";
import { uploadingQAandFile } from "./uploadingQAandFile";
import { questionnaireParser } from "./questionnaireParser";
import SignLogin from "../Authentication/Sign-login";
import CategoryCompletedPopup from "./CategoryCompletedPopup";
import questionsData from "./en-questions.json";
import questionsData_ar from "./ar-questions.json";
import { uploadingFeelingWell } from "./UploadingFeelingWell";
import UploadButton from "../UploadButton";
import translations from "../translations";
import "../style/questionnaire.css";
import "@fortawesome/fontawesome-free/css/all.css";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import CryptoJS from "crypto-js";

export function IamNotFeelingWell() {
	const {
		errMsg,
		setErrMsg,
		loading,
		signInForVisitor,
		setSignInForVisitor,
		setIsFileUploaded,
		isFileUploaded,
		activeLang,
		setFiles,
		files,
		preFilledQuestions,
		imNotFeelingWell,
		setimNotFeelingWell,
	} = useData();
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const [questions, setQuestions] = useState([]);
	const [filteredQuestions, setFilteredQuestions] = useState([]);
	const [questionnaireCompleted, setQuestionnaireCompleted] = useState(false);
	const [animateProgress, setAnimateProgress] = useState(false);
	const [answers, setAnswers] = useState({});
	const [answersInCategory, setAnswersInCategory] = useState({});
	const [otherText, setOtherText] = useState({});
	const [foodText, setFoodText] = useState({});
	const [inputText, setInputText] = useState({});
	const [medicationsText, setMedicationsText] = useState({});
	const [isFemale, setIsFemale] = useState(false);
	const [isGenderChanged, setIsGenderChanged] = useState("");
	const [filledAnswer, setFilledAnswer] = useState(false);
	const [formLoading, setFormLoading] = useState(false);
	const [completedCategory, setCompletedCategory] = useState(null);
	const lang = translations[activeLang];
	const [notFirstQues, setNotFirstQues] = useState(true);
	const [describe, setDescribe] = useState("");
	const navigate = useNavigate();
	const SECRET_KEY = "alhenaie";

	const encryptData = (data) => {
		return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
	};

	const decryptData = (ciphertext) => {
		try {
			const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
			const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
			return decryptedData;
		} catch (error) {
			console.error("Error while saving or parse the data", error);
			return null;
		}
	};

	useEffect(() => {
		try {
			if (!preFilledQuestions) {
				return ;
			} else {
				setAnswersInCategory({});
				setCurrentQuestionIndex(0);
				setFilledAnswer(true);
				toast.success("Your previous answers are saved");
			}
		} catch (error) {
			console.error("pasing error getting prefilled from server");
		}
	}, []);

	useEffect(() => {
		const extractedAnswers = {};
		let index = 0;
	
		for (const section in answersInCategory) {
			const questions = answersInCategory[section];
			
			if (Array.isArray(questions)) {
				questions.forEach((question) => {
					const { options } = question;
					if (options !== undefined && options !== null) {
						extractedAnswers[index++] = options;
					}
				});
			}
		}
		setAnswers({});
	}, [filledAnswer]);

	useEffect(() => {
		if (
			currentQuestionIndex > 0 &&
			currentQuestionIndex < filteredQuestions.length &&
			filteredQuestions[currentQuestionIndex].category !==
				filteredQuestions[currentQuestionIndex - 1].category
		) {
			setCompletedCategory(
				filteredQuestions[currentQuestionIndex - 1].category
			);
			setTimeout(() => {
				setCompletedCategory(null);
			}, 2000);
		}
	}, [currentQuestionIndex]);

	useEffect(() => {
		const nextButton = document.getElementById("next-btn");
		if (nextButton) {
			nextButton.focus();
		}
	}, [answers, currentQuestionIndex]);

	useEffect(() => {
		if (!imNotFeelingWell){
			if (!isFileUploaded){
				toast.error("Upload your lab test first");
				navigate(-1);
			}
		}
	}, [
		isFileUploaded,
		navigate,
		setFiles,
		setIsFileUploaded,
		setSignInForVisitor,
	]);

	window.onpopstate = () => {
		if (imNotFeelingWell) {
			setIsFileUploaded(false);
			setFiles([]);
			navigate("/LandingPage");
		}
		if (signInForVisitor) {
			setSignInForVisitor(false);
			navigate("/LandingPage");
		}
		window.onpopstate = null;
	};

	useEffect(() => {
		const getQuestions = () => {
			if (activeLang === "en"){
				setQuestions(flattenQuestions(questionsData));
			}
			else if (activeLang === "ar"){
				setQuestions(flattenQuestions(questionsData_ar));
			}
			else {
				console.error("Unsupported language");
			}
		};
		getQuestions();
	}, [setErrMsg]);

	useEffect(() => {
		const filterQuestionsByGender = (questions, isFemale) => {
			if (isFemale) 
                return questions;
			else
				return questions.filter(
					(question) => question.category !== "Women's Health"
				);
		};

		setFilteredQuestions(filterQuestionsByGender(questions, isFemale));
	}, [questions, isFemale]);

	useEffect(() => {
		setErrMsg("");
		if (currentQuestionIndex > filteredQuestions.length - 1) {
			setQuestionnaireCompleted(true);
		} else {
			setQuestionnaireCompleted(false);
		}
	}, [currentQuestionIndex, filteredQuestions.length]);

	const handleAnswer = async (e) => {
		e.preventDefault();
		const form = e.target;
		const formData = new FormData(form);
		const answer = formData.getAll("answer");
		const currentQuestion = filteredQuestions[currentQuestionIndex];

		setFormLoading(true);
		if (questionnaireParser(currentQuestion, answer, setErrMsg) === false) {
			setFormLoading(false);
			return;
		}

		const category = currentQuestion.category;
		const { id, label, text, type } = currentQuestion;

		const newAnswer = {
			id,
			label,
			text,
			type,
			options: [...new Set(answer)],
		};

		if (currentQuestion.category === "gender") {
			if (answer.includes("Female") || answer.includes("انثي")) {
        setIsFemale(true);
				if (answer[0] !== answersInCategory?.gender?.[0]?.options?.[0])
					setAnswers({});
        if (answer[0] === isGenderChanged)
          setFilledAnswer(true);
        if (answer[0] === isGenderChanged)
          setFilledAnswer(true);
			} else if (answer.includes("Male") || answer.includes("ذكر")) {
				setIsFemale(false);
				if (answer[0] !== answersInCategory?.gender?.[0]?.options?.[0])
					setAnswers({});
        if (answer[0] === isGenderChanged)
          setFilledAnswer(true);
			} else {
				setErrMsg("Not a valid choice");
				setFormLoading(false);
				return;
			}
		}
		setAnswersInCategory((prevAnswers) => {
			const updatedAnswers = { ...prevAnswers };
			if (category) {
				if (!updatedAnswers[category]) {
					updatedAnswers[category] = [];
				}
				updatedAnswers[category] = updatedAnswers[category].filter(
					(a) => a.id !== id
				);

				updatedAnswers[category].push(newAnswer);
			}
			return updatedAnswers;
		});

		setAnimateProgress(true);
		setTimeout(() => {
			setAnimateProgress(false);
			if (currentQuestionIndex <= filteredQuestions.length - 1) {
				setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
			}
			setFormLoading(false);
			form.reset();
		}, 300);
	};

	const handleBack = () => {
		if (currentQuestionIndex > 0) {
			setAnimateProgress(true);
			setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
		} else if (!notFirstQues)
			setNotFirstQues(true);
	};

	const handleOtherChange = (e) => {
		setOtherText((prev) => ({
			...prev,
			[currentQuestionIndex]: e.target.value,
		}));
	};

	const handleFoodChange = (e) => {
		setFoodText((prev) => ({
			...prev,
			[currentQuestionIndex]: e.target.value,
		}));
	};

	const handleMedicationsChange = (e) => {
		setMedicationsText((prev) => ({
			...prev,
			[currentQuestionIndex]: e.target.value,
		}));
	};

	const handleInputText = (e) => {
		setInputText((prev) => ({
			...prev,
			[currentQuestionIndex]: e.target.value,
		}));
	};

	const specifyOptions = {
		handleInputText,
		inputText: inputText[currentQuestionIndex] || "",
		otherText: otherText[currentQuestionIndex] || "",
		handleOtherChange,
		foodText: foodText[currentQuestionIndex] || "",
		handleFoodChange,
		medicationsText: medicationsText[currentQuestionIndex] || "",
		handleMedicationsChange,
		describe:describe || "",

	};

	const handleSubmit = () => {
		let genderAnswer = answersInCategory?.gender?.[0]?.options?.[0];
		let updatedAnswers = answersInCategory;
		if (genderAnswer && (genderAnswer === "Male" || genderAnswer === "ذكر")) {
			updatedAnswers = Object.keys(answersInCategory).reduce(
				(filteredQuestions, category) => {
					if (category !== "Women's Health") {
						filteredQuestions[category] = answersInCategory[category];
					}
					return filteredQuestions;
				},
				{}
			);
		}

		uploadingFeelingWell(
			updatedAnswers,
			setErrMsg,
			navigate,
			setCurrentQuestionIndex
		);
	};

	const handleClose = (event) => {
		event.preventDefault();
		toast.success(lang.ProgressMsg);
		setIsFileUploaded(false);
		setimNotFeelingWell(false);
		setFiles([]);
		navigate("/LandingPage");
	};

	if (!questions || questions.length === 0) {
		return <LoadingSpinner />;
	}

	const currentCategory = filteredQuestions[currentQuestionIndex]?.category;
	const categoryIconClass = categoryIcons[currentCategory];

	const translatedTitle = (categoryTitle) => {
		if (activeLang === "ar") {
			if (categoryTitle === "gender") return "النوع";
			else if (categoryTitle === "General Health Information")
				return "معلومات صحية عامة";
			else if (categoryTitle === "Medical History") return "التاريخ الطبي";
			else if (categoryTitle === "Lifestyle and Wellbeing") return "نمط الحياة";
			else if (categoryTitle === "Women's Health") return "صحة المرأة";
			else if (categoryTitle === "Extended Health History")
				return "التاريخ الطبي (مفصل)";
			else return "";
		} else {
			return categoryTitle;
		}
	};

	const handleDescribe = (e) =>{
		console.log(e.target.value);
		setDescribe(e.target.value);
	  }
	  const handleDescribeSubmit = (e) =>{
		console.log("Submit");
		setNotFirstQues(!notFirstQues);
	  }
	return (
		<>
			{signInForVisitor ? (
				<SignLogin />
			) : (
				<>
					{loading ? (
						<LoadingResults />
					) : (
						<>
							<div className="questionnaire-bg">
								<div className="questionnaire-header">
									<h1 className="questionnaire-title">{lang.Questionnaire}</h1>
								</div>
								<div className="container">
									<div className="centered-questionnaire">
										<Progress
											currentQuestionIndex={currentQuestionIndex}
											totalQuestions={filteredQuestions.length}
											animateProgress={animateProgress}
											questionnaireCompleted={questionnaireCompleted}
										/>
										{formLoading && <LoadingSpinner />}
										{!formLoading && (
											<form
												className={`questionnaire-form ${
													activeLang === "ar" ? "onlyRtl" : ""
												}`}
												data-testid="questionnaire"
												onSubmit={handleAnswer}
											>
												<i
													className="fa fa-window-close icon-close-questionnaire"
													aria-hidden="true"
													onClick={handleClose}
												></i>
												{errMsg && (
													<div className="error-message alert alert-danger fade-out">
														{errMsg}
													</div>
												)}
												<div className="header fade-in">
													<h2>{translatedTitle(currentCategory)}</h2>
													<div
														className={`category-icon ${categoryIconClass}`}
													></div>
												</div>
												{currentQuestionIndex > 0 &&
													!questionnaireCompleted && (
														<p className="fade-in">
															{lang.questionsNum} {currentQuestionIndex + 1}{" "}
															{lang.questionsNumOf} {filteredQuestions.length}
														</p>
													)}
												{completedCategory && (
													<CategoryCompletedPopup
														categoryName={completedCategory}
													/>
												)}
										{!notFirstQues && !completedCategory && (
											<Question
											question={filteredQuestions[currentQuestionIndex]}
											currentAnswer={answers[currentQuestionIndex] || []}
											setAnswers={setAnswers}
											currentQuestionIndex={currentQuestionIndex}
											specifyOptions={specifyOptions}
										/>
										)}
										{notFirstQues && (
											<div >
											<label className='label-q fade-in'>Describe your health condition :</label>
											<input 
											type="text"
											name="describe"
											value={describe}
											onChange={handleDescribe}
											/>
											<button 
											className="nextbtn"
											onClick={handleDescribeSubmit}
											>
											Next
											</button>
										</div>
										)}
										{questionnaireCompleted && (
											<div className="questions-info">
												<h2>{lang.questionsInfoH2}</h2>
												<p>{lang.questionsInfoP}</p>
												<span>{lang.questionsInfoSpan}</span>
											</div>
										)}
												<br />
												<div
													className={`${
														activeLang === "ar"
															? "flex-row-reverse"
															: "flex-row"
													}`}
												>
													{!completedCategory && (
														<button
															className="backbtn fade-in"
															type="button"
															onClick={handleBack}
														>
															{lang.Q_backbtn}
														</button>
													)}
													{!questionnaireCompleted && !completedCategory && !notFirstQues && (
														<button
															className="nextbtn fade-in"
															id="next-btn"
															type="submit"
															disabled={questionnaireCompleted}
															autoFocus
														>
															{lang.Q_nextbtn}
														</button>
													)}
													{questionnaireCompleted && !notFirstQues &&(
														<button
															className="nextbtn"
															type="button"
															onClick={handleSubmit}
														>
															{lang.Q_submitbtn}
														</button>
													)}
												</div>
											</form>
										)}
										{/* <UploadButton setFiles={setFiles} /> */}
									</div>
								</div>
							</div>
						</>
					)}
				</>
			)}
		</>
	);
}
