import React, { useState } from 'react';
import SaveButton from './SaveButton';
import { useData } from '../api/store';
import { validCountries } from './countries';
import translations from '../translations';

const LanguageRegion = () => {
  const { activeLang , setActiveLang, country, setCountry } = useData();
  const lang = translations[activeLang];

  const handleLanguageChange = (e) => {
    setActiveLang(e.target.value);
  };

  const handleRegionChange = (e) => {
    setCountry(e.target.value);
  };

  return (
    <div className="language-region-content settings-content">
      <h2>{lang.LanguageRegionSett}</h2>
      <div className="language-select-group">
        <label htmlFor="languageSelect">{lang.SelectLanguage}</label>
        <select
          className="language-select"
          id="languageSelect"
          value={activeLang}
          onChange={handleLanguageChange}
        >
          <option className="lang-option" value="en">English</option>
          <option className="lang-option" value="ar">عربي</option>
        </select>
      </div>
      <div className="region-select-group">
        <label htmlFor="regionSelect">{lang.SelectRegion}</label>
        <select
          className="region-select"
          id="regionSelect"
          value={country}
          onChange={handleRegionChange}
        >
          {validCountries[activeLang].map((country) => (
            <option className="region-option" key={country} value={country}>
              {country}
            </option>
          ))}
        </select>
      </div>

      <SaveButton
        language={activeLang}
        birthday=''
        country={country}
        subscribed=''
        name=''
      />
    </div>
  );
};

export default LanguageRegion;
