import axios from 'axios';
import { useData } from '../api/store';
import { setAuthTokenCookie } from './setAuthTokenCookie';
import toast from 'react-hot-toast';
const SEND_USER_OTP = process.env.REACT_APP_BASE_URL + "/api/send_login_otp/";

export async function sendOtpLogin(navigate) {
    const { email, validEmail, setErrMsg, setSuccessMsg, setOtpShow, setOtpToken } = useData.getState();
    
    if (validEmail && typeof email === 'string' && email.length > 0) {
        try {
            const otpResponse = await axios.post(SEND_USER_OTP, { email });
            const { status, data } = otpResponse;

            if (status === 200 || status === 202 || status == 201) {
                const { token } = data;
                if (typeof token === 'string' && token.length > 0) {
                    setSuccessMsg("Please check your email for OTP.");
                    toast.success("Please check your email for OTP.");
                    setOtpToken(token);
                    setAuthTokenCookie(token);
                    setOtpShow(true);
                } else {
                    throw new Error("Invalid token received");
                }
            } else if (status === 400) {
                setErrMsg("Invalid email format or bad request. Please try again.");
                throw new Error("Failed to get user token");
            } else if (status === 403) {
                setErrMsg("Non-registered user. Please sign up first.");
                throw new Error("Failed to get user token");
            } else {
                setErrMsg(`Unexpected status code: ${status}. Please try again.`);
                throw new Error("Failed to get user token");
            }
        } catch (error) {
            setErrMsg("Non-registered user. Please sign up first.");
            navigate('/SignLogin');
        }
    } else {
        setErrMsg("User creation failed");
        navigate('/SignLogin');
    }
}
