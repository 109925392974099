import React from 'react';

export const Progress = ({ currentQuestionIndex, totalQuestions, animateProgress, questionnaireCompleted }) => {
  if (currentQuestionIndex <= 0)
      totalQuestions = 0;

  return (
    <div className={`progress-container ${animateProgress ? 'animate' : ''}`}>
      <div className="progress-line" style={{ height: `88%` }}></div>
      <div className={`progress-circle top-circle ${currentQuestionIndex > 0 ? 'checked' : ''}`}>
        {currentQuestionIndex > 0 ? <i className="fa fa-check"></i> : ''}
      </div>
      <div className="progress-circle middle-circle">
      {
        questionnaireCompleted ? <i className="fa fa-check"></i> : currentQuestionIndex + 1
      }
      </div>
      <div className="progress-circle bottom-circle">
       {
        questionnaireCompleted ? <i className="fa fa-check" style={{ color : 'darkgreen'}}></i> : totalQuestions
       }
      </div>
    </div>
  );
};
