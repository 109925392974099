import React from 'react';
import { BsDribbble, BsFacebook, BsGithub, BsInstagram, BsTwitter } from "react-icons/bs";
import './style/Footer.css'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-grid">
          <div className="footer-section">
            <h3 className="footer-title">Tahlili Solutions</h3>
            <ul className="footer-links">
              <li><a href="https://www.tahlili-solutions.com/AboutUs">About</a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3 className="footer-title">Help Center</h3>
            <ul className="footer-links">
              {/* <li><a href="#">Discord Server</a></li> */}
              <li><a href="https://www.instagram.com/p/C_OkAIxs1Qr/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==">Intagram</a></li>
              <li><a href="https://x.com/TahliliSol/status/1828898590032314753">X - Twitter</a></li>
              <li><a href="https://www.facebook.com/profile.php?id=61558580654350">Facebook</a></li>
              <li><a href="https://www.tahlili-solutions.com/ContactUs">Contact Us</a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3 className="footer-title">Legal</h3>
            <ul className="footer-links">
              <li><a href="https://www.tahlili-solutions.com/PrivacyPolicy#privacy_policy">Privacy Policy</a></li>
              <li><a href="https://www.tahlili-solutions.com/PrivacyPolicy#terms_and_conditions">Terms &amp; Conditions</a></li>
              <li><a href="https://www.tahlili-solutions.com/PrivacyPolicy#consent">User &amp; Consent</a></li>
            </ul>
          </div>
          {/* <div className="footer-section">
            <h3 className="footer-title">Download</h3>
            <ul className="footer-links">
              <li><a href="#">iOS</a></li>
              <li><a href="#">Android</a></li>
            </ul>
          </div> */}
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 Tahlili</p>
          <div className="footer-social-icons">
            <a href="https://www.facebook.com/profile.php?id=61558580654350"><BsFacebook /></a>
            <a href="https://www.instagram.com/p/C_OkAIxs1Qr/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="><BsInstagram /></a>
            <a href="https://x.com/TahliliSol/status/1828898590032314753"><BsTwitter /></a>
            {/* <a href="#"><BsGithub /></a> */}
            {/* <a href="#"><BsDribbble /></a> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
