import React from 'react';
import SignLogin from './Sign-login';
import SignLoginMobile from './SignLoginMobile';

function AuthVeiwer() {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {isMobile ? <SignLoginMobile /> : <SignLogin />}
    </>
  );
}

export default AuthVeiwer;
