import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Consent } from './Consent';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { handleGoogleSignUp } from './handleGoogleSignUp';
import { handleFacebookSignUp } from './handleFacebookSignUp';
import { FacebookAuth } from './FacebookAuth';
import { sendOtp } from './sendOtp';
import OtpPopUp from './OtpPopUp';
import { useData } from '../api/store';
import ShortConsent from './ShortConsent';
import LoadingPage from '../LoadingPage';
import toast from 'react-hot-toast';
import translations from '../translations'
import '@fortawesome/fontawesome-free/css/all.css';
import '../style/Sign-login.css';


export function SignUp() {
  const userRef = useRef(null);
  const emailRef = useRef(null);
  const googleButtonRef = useRef(null);
  const facebookButtonRef = useRef(null);
  const [userFocus, setUserFocus] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [consentChecked, setConsentChecked] = useState(false);
  const [showLongConsent, setShowLongConsent] = useState(false);
  const [loadingPage , setLoadingPage ] = useState(false)
  const navigate = useNavigate();

  const {
    email,
    user,
    validName,
    validEmail,
    showConsent,
    successMsg,
    errMsg,
    success,
    consent,
    otpShow,
    setConsent,
    setUser,
    setEmail,
    setValidName,
    setValidEmail,
    setShowConsent,
    setErrMsg,
    activeLang
  } = useData();
  const lang = translations[activeLang];

  const USER_REGX = /^[a-zA-Z][a-zA-Z0-9_ \-]{1,23}$/;
  const EMAIL_REGX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  useEffect(() => {
    if (userFocus.current) {
      userRef.current.focus();
      setConsent(false);
    }
  }, []);

  useEffect(() => {
    const result = USER_REGX.test(user);
    setValidName(result);
  }, [user]);

  useEffect(() => {
    const result = EMAIL_REGX.test(email);
    setValidEmail(result);
  }, [email]);

  useEffect(() => {
    setErrMsg("");
    setConsent(false);
  }, [userFocus, emailFocus]);

  const handleSignUpSubmit = (e) => {
    e.preventDefault();
    if (validName && validEmail && !consent) {
      setShowConsent(true);
    }
    if (validName && validEmail && consent) {
      setLoadingPage(true);
      sendOtp(navigate);
      setLoadingPage(false);
      setShowConsent(false);
    } else if (!validName && !validEmail) {
      toast.error("Username and email are invalid.");
      return;
    } else if (!validName) {
      toast.error("User name is invalid.");
      return;
    } else if (!validEmail) {
      toast.error("Email is invalid.");
      return;
    }
  };

  const handleGoogleSignUpSuccess = async (response) => {
    if (consent){
      setLoadingPage(true);
      await handleGoogleSignUp(response, navigate);
      setLoadingPage(false);
    }
    else
    {
        toast.error('Please agree to the terms and conditions.');
        setShowLongConsent(true);
    }
  };

  const handleGoogleSignUpFailure = (error) => {
    toast.error('Google sign in failed.');
  };

  const handleFacebookSignUpSuccess = async (response) => {
    setLoadingPage(true);
    if (consent)
      await handleFacebookSignUp(response, navigate);
    else
      toast.error('Please agree to the terms and conditions.');
    setLoadingPage(false);
  };

  const handleFacebookSignUpFailure = (error) => {
    toast.error('Facebook sign in failed.');
  };

  const handleConsentChange = (e) => {
    setConsentChecked(true);
    setConsent(true);
    setShowLongConsent(!showLongConsent);
  };


  const handleReload = () => {
    setEmail('');
    setUser('');
    setShowLongConsent(false);
  }

  const handleShowLongConsent = (e) => {
    if (!e.target.checked){
      setConsentChecked(false);
      setConsent(false);
    }
    else {
      setShowLongConsent(e.target.checked);
    }
  }


  return (
    <>
      {loadingPage && <LoadingPage />}
      {showConsent && <Consent />}
      {showLongConsent && <ShortConsent handleCheckboxChange={handleConsentChange} handleReload={handleReload}/>}
      {otpShow && <OtpPopUp SignType={'SignUp'} />}
      {!otpShow && !showConsent &&
      <form onSubmit={handleSignUpSubmit}>
        <p className={success ? 'successMsg' : 'offScreen'}>{successMsg}</p>
        <p className={errMsg ? 'errMsg' : 'offScreen'}>{errMsg}</p>
        <h1>{lang.CreateAccount}</h1>
        <input
          type="text"
          className='sign-up-input'
          ref={userRef}
          placeholder={lang.CreateAccountName}
          onChange={(e) => setUser(e.target.value)}
          required
          autoComplete="off"
          aria-invalid={validName ? 'false' : 'true'}
          onFocus={() => setUserFocus(true)}
          onBlur={() => setUserFocus(false)}
        />
        <p className={userFocus && user && !validName ? 'instructions' : 'offScreen'}>
          <i className="fa fa-info-circle info-icon"></i><br />
          {lang.CreateAccountEmailInstructions1}<br />
          {lang.CreateAccountEmailInstructions2}<br />
          {lang.CreateAccountEmailInstructions3}
        </p>
        <input
          className='sign-up-input'
          type="email"
          ref={emailRef}
          placeholder={lang.CreateAccountEmail}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button className="sign-in-up-btn" type="submit">{lang.SignUpBtn}</button>
        <span> {lang.OrSignUpWith} </span>
        <div className="consent-checkbox">
          <input
            type="checkbox"
            id="consent"
            checked={consent}
            onChange={handleShowLongConsent}
          />
          <label htmlFor="consent"> {lang.IAgreeSignUp} </label>
        </div>
        <div className="social-icons">
        <div className="button-content">
            <GoogleLogin
              onSuccess={handleGoogleSignUpSuccess}
              onFailure={handleGoogleSignUpFailure}
              text=""
              theme="outline"
              size="medium"
              width="50"
              shape="circle"
              logo_alignment="center"
            />
          </div>
          <FacebookAuth
            onSuccess={handleFacebookSignUpSuccess}
            onFailure={handleFacebookSignUpFailure}
          />
        </div>
      </form>}
    </>
  );
}
