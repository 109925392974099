import {jwtDecode} from 'jwt-decode';
import { useData } from '../api/store';
import toast from 'react-hot-toast';
import axios from 'axios';
import { setAuthTokenCookie } from './setAuthTokenCookie';
const GET_USER_TOKEN = process.env.REACT_APP_BASE_URL + "/api/register_user/";


export async function handleGoogleSignUp(credentialResponse , navigate) {
    const { setLoggedIn , setLoggedInUser , signInForVisitor , setSignInForVisitor} = useData.getState();
    const { credential } = credentialResponse;
    const decodedToken = jwtDecode(credential);
    const { email , name} = decodedToken;
    try {
      const response = await axios.post(GET_USER_TOKEN, { 
        vendor: "google",
        email: email,
        access_token: credential,
        name: name,
        consented: "True",
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });
      if (response.status === 200 || response.status === 201) {
        setAuthTokenCookie(response.data.token); 
        setLoggedInUser({
          username : response.data.name,
          email : response.data.email,
        })
        toast.success("You Signed up successfully 👍")
        if (signInForVisitor)
        {
          setSignInForVisitor(false);
          return ;
        } else {
            navigate(-1);
          }
      }
    } catch (error) {          
        setLoggedIn(false);
        toast.error('Network Error, Try again later.');
      return;
    }
}